import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { useRouter } from 'next/router'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.APP_INSIGHT_KEY || publicRuntimeConfig.APP_INSIGHT_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: useRouter }
    }
  }
})
appInsights.loadAppInsights()
var telemetryInitializer = (envelope) => {
  envelope.tags['ai.cloud.role'] = 'lintas-port-web'
}
appInsights.addTelemetryInitializer(telemetryInitializer)
export { reactPlugin, appInsights }