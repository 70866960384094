import styled from 'styled-components'
import { Dialog, DialogContent } from '@material-ui/core'

export const DialogWrapper = styled(Dialog)`
  &
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    border-radius: 10px;
  }
`

export const DialogCon = styled(DialogContent)`
  height: 18vh;
  width: 15vw;
  border-radius: 10px 10px 0px 0px;
  background: #f4f6f9 0% 0% no-repeat padding-box;
`
export const DivBtnDel = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const SureDelete = styled.div`
  text-align: center;
  padding-top: 24px;
  font-weight: bold;
`

export const TitleDelete = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DivIconDel = styled.div`
  width: 100%;
  height: 85px;
  font-size: 80px;
  margin-top: -10px;
  text-align: center;
`

export const ButtonTitleWrapper = styled.div`
  height: 17.9vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
export const QuestionText = styled.div`
  font-size: 1.5vh;
  font-weight: 600;
  margin-bottom: 3vh;
  text-align: center;
`
export const ButtonWrapper = styled.div`
  margin: 0px 0.5vh;
  display: inline-block;
`
export const TitleWrapper = styled.div`
  font-size: 3vh;
  color: #343434;
  font-weight: bold;
`
