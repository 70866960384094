import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import Select from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/Close'
import MenuItem from '@material-ui/core/MenuItem'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

import { Colors } from '../../styles'
import { Button } from '../../components'

export const Container = styled.div`
  height: 100vh;
  width: 411px;.
  box-shadow: 0px 0px 6px #00000029;
  background-color: #ffffff;
  padding: 0 25px;
  padding-top: 70px;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto;
`

export const TopWrapper = styled.div`
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: #ffffff;
  z-index: 999;
  opacity: 1;
  padding-bottom: 5px;
`

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeadTitle = styled.p`
  color: #7d7d7d;
  font-size: 25px;
  font-family: 'Open Sans', sans-serif;
`

export const IconHeadWrapper = styled.div`
  color: #7d7d7d;
`

export const CrossIconHead = styled(CloseIcon)`
  cursor: pointer;
  &.MuiSvgIcon-root {
    color: #7d7d7d;
    font-size: 25px;
    font-weight: bolder;
  }
`

export const CrossIconRed = styled(CloseIcon)`
  cursor: pointer;
  &.MuiSvgIcon-root {
    font-size: 18px;
    color: #ff0000;
    font-weight: bolder;
    margin-left: 5px;
  }
`

export const DescText = styled.p`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #7d7d7d;
`

export const DropDownWrapper = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 5px;
  &:hover {
    background-color: #efefef;
  }
  &:active {
    background-color: #efefef;
  }
`

export const DragIcon = styled(DragIndicatorIcon)`
  &.MuiSvgIcon-root {
    color: #ffffff;
  }

  ${DropDownWrapper}:hover & {
    color: #7d7d7d;
  }

  ${DropDownWrapper}:active & {
    color: #7d7d7d;
  }
`

export const DropDownSelect = styled(Select)`
  width: 100%;
  margin: 15px 0;
  .MuiOutlinedInput-input {
    padding: 9px 15px;
  }
  .MuiInputBase-input {
    color: #7d7d7d;
    font-family: 'Open Sans', sans-serif;
  }
`

export const ErrorIcon = styled(ErrorOutlineIcon)`
  &.MuiSvgIcon-root {
    color: blue;
    font-size: 18px;
    margin-left: 5px;
  }
`

export const Srno = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 5px;
  padding-left: 45px;
  border-radius: 5px;
  color: #7d7d7d;
  background-color: #eeeeee;
  font-size: 15px;
`

export const DropDownItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }
  &.MuiButtonBase-root {
    color: #7d7d7d;
  }
`

export const DropDownContainer = styled.div`
  margin-top: 45px;
`

export const AddWrapper = styled.span`
  margin-top: 30px;
  cursor: pointer;
  display: block;
`

export const PlusIcon = styled(AddIcon)`
  &.MuiSvgIcon-root {
    color: blue;
    font-size: 14px;
  }
`

export const AddText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin-left: 5px;
`

export const SaveButton = styled(Button)`
  width: 152px;
  height: 40px;
  background: #95c123;
  background: ${(p) => p.disable ? Colors.grey : '#95c123'};
  margin-top: 100px;
  margin-bottom: 50px;
  display: block;
`
