import React from 'react'
import Dialog from '@material-ui/core/Dialog'
const Modal = (props) => {
  return (
    <div>
      <Dialog
        disableBackdropClick
        open={props.showModal}
        maxWidth={props.maxWidth}
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
      >
        {props.children}
      </Dialog>
    </div>
  )
}
export default Modal
