import React, { useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { Popup } from '@progress/kendo-react-popup'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

const CustomPopup = (props) => {
  return <Popup {...props} style={{ zIndex: 10000 }} />
}

const PickerComponent = (props) => {
  let cld = useRef(null)

  const limit = {}
  if (props.disableFuture) {
    limit.max = new Date(new Date().setHours(23, 59, 59, 999))
  }
  if (props.disablePast) {
    limit.min = new Date(new Date().setHours(0, 0, 0, 0))
  }

  return (
    <DateTimePicker
      ref={cld}
      id={props.id}
      popup={CustomPopup}
      format='dd/MM/yyyy HH:mm'
      formatPlaceholder={{ year: 'yyyy', month: 'mm', day: 'dd', hour: 'hh', minute: 'mm' }}
      width='100%'
      value={props.value ? new Date(props.value) : null}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={() => {
        localStorage.setItem('calendar', cld.current ? cld.current.state.show : false)
      }}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      {...limit}
    />
  )
}

const InputDateTime = (props) => {
  return (
    <TextField
      InputProps={{
        inputComponent: PickerComponent,
        inputProps: {
          disableFuture: props.disableFuture,
          disablePast: props.disablePast,
          tabIndex: props.tabIndex,
          id: props.id,
          disabled: !!props.readOnly,
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      error={props.shouldUseOnChange ? '' : props.form.touched[props.name] && !!props.form.errors[props.name]}
      helperText={
        props.shouldUseOnChange ? ''
          :
          props.form.touched[props.name] && props.form.errors[props.name]
            ? props.form.errors[props.name]
            : props.disableHelperText
              ? null
              : ' '
      }
      label={props.label}
      value={props.shouldUseOnChange ? props.value : props.form.values[props.name] || props.value || null}
      onChange={
        props.shouldUseOnChange ? ({ value }) => props.onChange(value)
          :
          props.onChange
            ? ({ value: v }) => props.onChange(moment(v).toISOString())
            : ({ value: v }) => props.form.setFieldValue(props.name, moment(v).toISOString())
      }
      onBlur={props.onBlur ? (param) => props.onBlur(param) : null}
    />
  )
}

InputDateTime.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default InputDateTime
