import { CellStyle, NewDiv } from './cell.detail.styled'
import Tooltip from '@material-ui/core/Tooltip'

const CodeCell = (props) => {
  return (
    <td style={{ position: 'sticky', left: `${props.lock ? '100px' : '0px' }` }}
      className='k-grid-content-sticky'>
      <CellStyle id='m-detail-btn' {...props} >
        {props.disableTooltip ? (
          <div>{props.children}</div>
        ) : (
          <Tooltip title='Click here to view detail' arrow>
            <div>{props.children}</div>
          </Tooltip>
        )}
        {props.isToday && <NewDiv>New</NewDiv>}
      </CellStyle>
    </td>
  )
}

export default CodeCell
