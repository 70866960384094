import styled from 'styled-components'
import { DialogContent } from '@material-ui/core'
import { Dialog } from '@progress/kendo-react-dialogs'

export const DialogWrapper = styled(Dialog)`
  border-radius: 10px;

  & .k-widget.k-window.k-dialog {
    border-radius: 10px;
    width: auto;
    height: auto;
    box-shadow: 0px 0px 10px #00000029;
    & .k-content.k-window-content.k-dialog-content {
      padding: 0px;
    }
  }
`

export const DialogCon = styled(DialogContent)`
  height: 176px;
  border-radius: 10px 10px 0px 0px;
  background: #f4f6f9 0% 0% no-repeat padding-box;
`
export const DivBtnDel = styled.div`
  margin-top: 60px;
  text-align: center;
`

export const SureDelete = styled.div`
  text-align: center;
  padding-top: 24px;
  font-weight: bold;
`

export const TitleDelete = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DivIconDel = styled.div`
  width: 100%;
  height: 85px;
  font-size: 80px;
  margin-top: -10px;
  text-align: center;
`

export const ButtonTitleWrapper = styled.div`
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`
export const QuestionText = styled.div`
  font-size: 15px;
  font-weight: 600;
  width: 250px;
  text-align: center;
`
export const ButtonWrapper = styled.div`
  margin: 0px 5px;
  display: inline-block;
`
export const TitleWrapper = styled.div`
  font-size: 30px;
  color: #343434;
  font-weight: bold;
`
