
import color from '../../styles/color'
import fontSize from '../../styles/fontSize'
import { ButtonDetail as Btn } from './button-detail.styled'


const ButtonDetail = (props) =>
{
  return(<>

    {props.title && <div style={{ color:color.grey, fontSize:fontSize.extraSmall }}>{props.title}</div>}
    <Btn {...props}>
      {props.children}
    </Btn>
  </>)
}

export default ButtonDetail