import styled from 'styled-components'
import { Edit, Delete, Save, DeleteForever, Cancel } from '@material-ui/icons'

import { Colors, FontSize } from '~/styles'
import { InputDateTime as InputDate } from '~/components'

const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  height: ${p => p.tableHeight};
  border: 1px solid ${Colors.wrapGray};

  /* 
    & table#custom-table th.sticky-col, table#custom-table td.sticky-col {
      position: -webkit-sticky;
      position: sticky;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      left: 0px;
    }
  */
`

const ButtonIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const DeleteIcon = styled(Delete)`
  cursor: ${(p)=>p.status ? 'pointer' : 'cursor'};
  color: ${(p)=>p.status ? Colors.red : Colors.hintGrey};
`

const EditIcon = styled(Edit)`
  cursor: ${(p)=>p.status ? 'pointer' : 'cursor'};
  color: ${(p)=>p.status ? Colors.newGreen : Colors.hintGrey};
`

const SaveIcon = styled(Save)`
  cursor: ${(p)=>p.disabled ? 'cursor' : 'pointer'};
  color: ${(p)=>p.disabled ? Colors.hintGrey : Colors.softBlue};
`

const DiscardIcon = styled(DeleteForever)`
  cursor: pointer;
  color: ${Colors.red};
`

const CancelIcon = styled(Cancel)`
  cursor: pointer;
  color: ${Colors.red};
`

const TableHeaderRow = styled.tr`
  font-size: ${FontSize.medium}px;
`

const ColumnText = styled.p`
  font-size: ${FontSize.medium}px;
  color: ${Colors.cancelTextColor};
`

const HeaderCell = styled.th`
  padding: 10px;
`
const TextInfo = styled.p`
    font-size: 10px;
    line-height: 0;
    color: ${Colors.softBlue};
    letter-spacing: 0.1px;
`
const TextInfoEdit = styled.p`
    font-size: 10px;
    line-height: 0;
    color: ${Colors.softBlue};
    letter-spacing: 0.1px;
    margin-top: -8px;
`

const InputDateTime = styled(InputDate)`
    margin-top: -16px;
`

const ActionWhenInEditCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export default {
  TextInfo,
  EditIcon,
  SaveIcon,
  Container,
  ColumnText,
  DeleteIcon,
  CancelIcon,
  ButtonIcon,
  HeaderCell,
  DiscardIcon,
  TextInfoEdit,
  InputDateTime,
  TableHeaderRow,
  ActionWhenInEditCellWrapper,
}
