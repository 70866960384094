import {
  Logout,
  PortSvg,
  PdfIcon,
  DocxIcon,
  XlsxIcon,
  HomeIcon,
  PointHere,
  Satellite,
  Progress1,
  Progress2,
  Progress2Grey,
  Progress3,
  Progress3Red,
  Progress4,
  Progress5,
  Progress6,
  CloudIcon,
  VesselIcon,
  StreetView,
  LintasIcon,
  SearchIcon,
  ProgressGrey,
  ProgressDone,
  EquipmentIcon,
  ProgressError,
  FloatingCrane,
  SavedAsSubmit,
  FullScreenExit,
  FullScreenEnter,
  UnfinalizedWApprovalIcon,
  UnfinalizedWOApprovalIcon,
  Delete
} from './svg.component'

import {
  ProgGrey,
  CompGrey,
  FinalGrey,
  DraftGrey,
  AppRejGrey,
  WaitAppGrey,
  //
  ProgGreen,
  CompGreen,
  FinalGreen,
  DraftGreen,
  AppRejGreen,
  WaitAppGreen,
  //
  ProgRed,
  CompRed,
  FinalRed,
  DraftRed,
  AppRejRed,
  WaitAppRed
} from './svg.service-request.component'

export {
  Logout,
  PortSvg,
  PdfIcon,
  DocxIcon,
  XlsxIcon,
  HomeIcon,
  PointHere,
  Satellite,
  Progress1,
  Progress2,
  Progress2Grey,
  Progress3,
  Progress3Red,
  Progress4,
  Progress5,
  Progress6,
  CloudIcon,
  VesselIcon,
  SearchIcon,
  StreetView,
  LintasIcon,
  ProgressGrey,
  ProgressDone,
  ProgressError,
  EquipmentIcon,
  FloatingCrane,
  FullScreenExit,
  FullScreenEnter,
  Delete,
  //-----SERVICE-REQUEST-------//
  //grey
  ProgGrey,
  CompGrey,
  FinalGrey,
  DraftGrey,
  AppRejGrey,
  WaitAppGrey,
  //green
  ProgGreen,
  CompGreen,
  FinalGreen,
  DraftGreen,
  AppRejGreen,
  WaitAppGreen,
  //red
  ProgRed,
  CompRed,
  FinalRed,
  DraftRed,
  AppRejRed,
  WaitAppRed,
  SavedAsSubmit,
  UnfinalizedWApprovalIcon,
  UnfinalizedWOApprovalIcon
}
