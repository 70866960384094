import _ from 'lodash'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const InputSelect = (props) => {
  const [detailValue, setDetailValue] = useState('')
  const { id, name, form, label, options, optionText, optionValueKey } = props
  const value = options ? options.find((e) => e[optionValueKey] === form.values[name]) : {}

  useEffect(() => {
    const selected = options ? options.find((e) => e[optionValueKey] === form.values[name]) : {}
    if (selected) {
      setDetailValue(selected[optionText])
    }
  }, [form.values, options, optionValueKey, name])

  return (
    <>
      {!props.readOnly ? (
        <Autocomplete
          id={id}
          name={name}
          ListboxProps={{ style: { maxHeight: '200px' } }}
          style={{ ...props.style }}
          options={props.isOptionsNotOrdered ? options: _.orderBy(options, [optionText], ['asc'])}
          getOptionLabel={(option) => (option[optionText] ? option[optionText] : option)}
          getOptionSelected={(option, value) => (value ? option[optionText] === value : true)}
          value={value ? value[optionText] : ''}
          onChange={(e, v) => {
            if (v && v[optionValueKey] !== '') {
              if (name === 'ccu_code' || name === 'ccu_unit_code') {
                form.setFieldValue('ccu_code', v['ccu_code'])
                form.setFieldValue('ccu_unit_code', v['code'])
                form.setFieldValue('tare_weight', v['tare_weight'])
                form.setFieldValue('max_gross_weight', v['max_gross_weight'])
              } else {
                form.setFieldValue(name, v[optionValueKey])
              }
            } else {
              form.setFieldValue(name, '')
            }
          }}
          autoHighlight={true}
          renderInput={(params) => {
            params.inputProps.tabIndex = props.tabIndex
            return (
              <TextField
                {...params}
                label={label}
                error={form && name && form.touched[name] && !!form.errors[name]}
                helperText={
                  form && name && form.touched[name] && form.errors[name] ? form.errors[name] : ' '
                }
                placeholder={props.placeholder}
              />
            )
          }}
        />
      ) : (
        <TextField
          id={id}
          name={name}
          value={detailValue}
          disabled={props.disabled || false}
          style={{ ...props.style }}
          InputProps={{ readOnly: true }}
          helperText=' '
          label={label}
        />
      )}
    </>
  )
}

InputSelect.propTypes = {
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionText: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
}

export default InputSelect
