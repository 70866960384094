import Dialog from '@material-ui/core/Dialog'
import styled, { css } from 'styled-components'
import TextField from '@material-ui/core/TextField'

import Btn from '../button'
import { Colors, FontSize } from '../../styles'

export const ButtonCancelSaveContainer = styled.div`
    right: 20px;
    bottom: 10px;
    display: flex;
    margin: 20px 0;
    position: absolute;
    justify-content: flex-end;
`

export const ButtonCancel = styled(Btn)`
    width: 140px;
    height: 40px;
    font-size: 18px;
    margin-left: 5px;
    color: ${Colors.modalGrey};
    background-color: ${Colors.white};
    border: 1px solid ${Colors.wrapGray};

    .btn-cancel {

    }
`

export const ButtonSave = styled(Btn)`
    width: 140px;
    height: 40px;
    font-size: 18px;
    margin-left: 5px;
    color: ${Colors.white};
    background-color: ${Colors.softBlue};
    ${p => p.disable && `cursor: not-allowed; background-color: ${Colors.wrapGray};`}

    .btn-save {
        
    }
`

export const Wrapper = styled.div`
    height: 100%;
    padding: 25px;
`

export const Title = styled.h3`
    margin-block-end: 0;
    margin-block-start: 0;
    font-size: 24px;
`

export const Content = styled.div`
    margin-top: 32px;
`

export const Description = styled.p`
    padding: 15px 0;
    margin-block-end: 0;
    margin-block-start: 0;
    color: ${Colors.cancelTextColor};
    font-size: ${FontSize.mediumLarge}px;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const ButtonSend = styled(Btn)`
    width: 140px;
    margin-left: 5px;
    border: 1px solid ${Colors.wrapGray};
`

export const Modal = styled(Dialog)`
    .MuiDialog-paperWidthLg {
        min-height: 55vh;
        min-width: 650px;
    }

    .MuiDialog-paperWidthMd {
        min-height: 390px;
        min-width: 540px;
    }


    .MuiDialog-paperWidthXs {
        min-height: 100px;
        width: 421px;
    }
`

export const IconClose = styled.img`
    top: 18px;
    right: 18px;
    width: 30px;
    height: auto;
    cursor: pointer;
    position: absolute;
    ${() =>
  css`
        &:hover {
          padding: 3px;
          border-radius: 100px;
          background: #E0EEF5;
        }
      `}
    `

export const TextArea = styled(TextField)`
    width: 100%;
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const Container = styled.div`
    padding:30px 30px 30px 40px;
`

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
`
export const ContainerSub = styled.div`
    padding:10px 0 40px 0;
`

export const CenterText = styled.h2`
    text-align: center;
`

export const CenterTextDesc = styled.p`
    text-align: center;
`

export const ButtonWrapperBottom = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: center;
`
export const ContainerConfirmation=styled.div`
    padding:30px 30px 30px 40px;
    display:flex;
    align-items:center;
    flex-direction:column;
    text-align: center;
`
export const Icon=styled.img`
    width:30px;
    height:30px;
`
export const Paragraph=styled.p`
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 62px;
`