import styled from 'styled-components'
import { Colors } from '../../styles'
import { Grid } from '@material-ui/core'

export const Container =styled.div`
    display: flex;
    flex-direction: column;
`
export const ErrorText= styled.p`
    top:6em;
    font-size:12px;
    position: absolute;
    color:${Colors.red};

`
export const Wrapper=styled.div`
    height:3.5vh;
    display: flex;
    border-radius: 6px;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    box-sizing: border-box;
    background: ${Colors.white};
    border: 1px solid ${Colors.borderGrey};
`
export const Box = styled(Grid)`
    width: ${p => p.width ? p.width : 'auto'}px;
    margin-left: 20px;
`