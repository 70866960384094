import styled from 'styled-components'

import { Colors } from '../../../styles'

export const CellStyle = styled.a`
  cursor: pointer;
  display: flex;
  color: ${Colors.softBlue} !important;
  align-items: center;
`
export const NewDiv = styled.div`
  margin: 0px 5px;
  background: #71af0057 0% 0% no-repeat padding-box;
  border-radius: 18px;
  text-align: center;
  color: #343f54;
  width: 60px;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
`
