import styled from 'styled-components'
import Input from '@material-ui/core/Input'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const TextFilter = styled(Input)`
  width: 100%;
  &.MuiInput-root {
    color: white !important;
  }
  &.MuiInput-underline:before {
    color: white;
    border-bottom: 1px solid white !important;
  }
  &.MuiInput-underline:after {
    color: white;
    border-bottom: 1px solid white !important;
  }
  & .MuiInputBase-input {
    font-size: 14px;
  }
`
export const TextFilterDate = styled(KeyboardDatePicker)`
  & div {
    &.MuiInput-underline:before,
    &.MuiInput-underline:after {
      border-bottom: 1px solid white;
    }
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid white;
    }
    & .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      color: white;
    }
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd::placeholder {
      color: white !important;
    }
    & div button {
      color: white;
    }
    & .MuiInputBase-input {
      font-size: 14px;
    }
  }
`
export const Icon = styled.span`
  color: white;
`
