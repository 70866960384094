import {
  Inject,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  ScheduleComponent,
  ResourceDirective,
  ResourcesDirective,
  Day,
  Week,
  TimelineMonth,
} from '@syncfusion/ej2-react-schedule'
import { loadCldr } from '@syncfusion/ej2-base'

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/en-GB/ca-gregorian.json'),
  require('cldr-data/main/en-GB/numbers.json'),
  require('cldr-data/main/en-GB/timeZoneNames.json')
)

const SchedulerPrintOut = (props) => {
  const {
    data,
    date,
    group,
    listVessel,
    eventTemplate,
    headerMonthTemplate,
    resourceHeaderTemplate,
    onPopupOpen,
    paper,
    eventRendered,
  } = props

  return (
    <ScheduleComponent
      // rowAutoHeight={true}
      popupOpen={onPopupOpen}
      locale='en-GB'
      height={paper === 'A3' ? '2500px' : '2500px'}
      currentView='TimelineDay'
      selectedDate={date}
      eventSettings={{
        dataSource: data,
        fields: {
          startTime: { title: 'Plan_Arrival', name: 'Plan_Arrival' },
          endTime: { title: 'Plan_Depart', name: 'Plan_Depart' },
        },
      }}
      eventRendered={eventRendered}
      group={group}
      resourceHeaderTemplate={resourceHeaderTemplate}
      width={paper === 'A3' ? '5738px' : '4000px'}
      {...props}
    >
      <ResourcesDirective>
        <ResourceDirective
          field='Jetty_Code'
          title='Jetty'
          name='Jetties'
          allowMultiple={false}
          dataSource={listVessel}
          textField='name'
          idField='Jetty_Code'
        ></ResourceDirective>
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective
          option='TimelineDay'
          displayName='Month'
          interval={props.day}
          timeScale={{ enable: false }}
          eventTemplate={eventTemplate}
          dateHeaderTemplate={headerMonthTemplate}
        />
      </ViewsDirective>
      <Inject services={[Day, Week, TimelineViews, TimelineMonth]} />
    </ScheduleComponent>
  )
}

export default SchedulerPrintOut
