import InputAdornment from '@material-ui/core/InputAdornment'

import {
  MuiPickersUtilsProvider, //KeyboardDatePicker
} from '@material-ui/pickers'
//KeyboardTimePicker,
import DateFnsUtils from '@date-io/date-fns'

import { Icon, TextFilter, TextFilterDate } from './filter.styled'
// import InputDateTime from '../../input-datetime'

const TextFilterCell = (props) => {
  const onChange = (event) => {
    props.onChange({
      operator: 'contains',
      value: event.target.value,
      syntheticEvent: event.syntheticEvent,
    })
  }

  return (
    <div>
      {props.filterType === 'date' ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextFilterDate
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            placeholder='DD/MM/YYYY'
            id={`m_grid_filter_${props.field}`}
            value={props.value}
            autoOk={true}
            error={false}
            helperText={null}
            onChange={(value) =>
              onChange({
                target: {
                  value: value,
                },
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ) : (
        // <InputDateTime id={`m_grid_filter_${props.field}`} value={props.value} />
        <TextFilter
          value={props.value ? props.value : ''}
          type={props.filterType === 'numeric' ? 'number' : props.filterType}
          onChange={onChange}
          id={`m_grid_filter_${props.field}`}
          endAdornment={
            <InputAdornment position='end'>
              <Icon className='k-icon k-i-filter' />
            </InputAdornment>
          }
        />
      )}
    </div>
  )
}

export { TextFilterCell }
