import styled from 'styled-components'

// import { Colors } from '../../styles'

export const ButtonAddContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
`
