import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from '@material-ui/lab/Pagination'
import { Colors, FontSize } from '../../../styles'

export const PagerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border-top: 1px solid #e2e2e2;
`

export const RightContainer = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
`

export const DropDownSelect = styled(Select)`
  width: 75px;
  margin-right: 10px;
  /* .MuiSelect-select {
    padding-left: 10px;
  } */
  & .MuiInputBase-input {
    font-size: 14px;
    color: #656565;
    padding: 5px;
  }
`
export const DropDownItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 14px;
  }
  &.MuiButtonBase-root {
    color: #656565;
  }
  &.MuiListItem-button:hover {
    background: ${Colors.softBlue};
    color: ${Colors.pureWhite};
  }
`

export const Paginate = styled(Pagination)`
  display: inline-block;
  margin-right: 10px;
  .MuiPaginationItem-root {
    color: #656565;
    font-size: 14px;
    border-radius: 0px;
    &.Mui-selected {
      background: ${Colors.softBlue};
      color: ${Colors.pureWhite};
    }
  }
`

export const SpanRightText = styled.span`
  display: inline-block;
  width: 150px;
  text-align: center;
  font-size: ${FontSize.medium}px;
`
