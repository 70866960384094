import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'
import { PrintOutlined } from '@material-ui/icons'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import IconButton from '@material-ui/core/IconButton'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import {
  Title,
  Header,
  Vessel,
  DivTitle,
  SubTitle,
  FormContainer,
  TitleContainer,
  VesselContainer,
  FormContainerStatic,
  DialogStyle,
  ButtonPrint,
  ButtonUnfinalized,
  PopoupUnfinalized,
  UnfinalizedButton,
  IconButtonUnfinalized,
  UnfinalizedButtonTitle,
  UnfinalizedButtonSubitle,
} from './master-modal.styled'
import { Images } from '../../assets'
import { Colors, FontSize } from '../../styles'
import { Button, Loading } from '../../components'
import ProgressContainer from './master-modal.progress.component'
import { UnfinalizedWApprovalIcon, UnfinalizedWOApprovalIcon } from '../svg'

const MasterModal = (props) => {
  const { utilizeLoading, isLoading } = props

  const _renderMainComponent = () => (
    <>
      {(props.isProgress && <ProgressContainer step={props.step} />) ||
        (props.isDetailSrModal && <ProgressContainer step={props.step} />)}
      {props.hasSubtitle && !props.isProgress && !props.isPrintPreview && (
        <VesselContainer>
          {props.isSrModal ? (
            <Vessel src={Images.jettyLogo} />
          ) : props.isPrintOut ? (
            <Vessel src={Images.Print} />
          ) : (
            <Vessel src={Images.jetty} />
          )}
          <SubTitle>{props.subTitle}<br/><span style={{ fontSize:'14px', color:Colors.softGrey }}>{props.subSubTitle}</span></SubTitle>


          {props.isExecutionSR && (
            <Button
              type='submit'
              color='blue'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_save_sr_exec_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleUpdate}
            >
              <SaveIcon style={{ marginRight: '10px', color: 'white', fontSize: '20px' }} />
              <p style={{ marginRight: '10px' }}>SAVE</p>
            </Button>
          )}
          {props.cancelable && (
            <Button
              type='submit'
              color='red'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_cancel_sr_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleCancel}
            >
              <RemoveCircleIcon style={{ marginRight: '10px', color: 'white', fontSize: '20px' }} />
              <p style={{ marginRight: '10px' }}>CANCEL</p>
            </Button>
          )}
          {props.isSrModal &&
            !props.edit &&
            props.add &&
            !props.isRevise &&
            !props.isApproved &&
            !props.isWaitApproved && (
            <Button
              type='submit'
              color='blue'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_save_draft_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleSaveDraft}
            >
              <SaveIcon style={{ marginRight: '10px', color: 'white', fontSize: '20px' }} />
                SAVE AS DRAFT
            </Button>
          )}
          {props.isSrModal && props.isWaitApproved && !props.isDetailSrModal && (
            <Button
              type='submit'
              color='blue'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_save_waiting_approval_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleSave}
            >
              <SaveIcon style={{ marginRight: '10px', color: 'white', fontSize: '20px' }} />
              <p style={{ marginRight: '10px' }}>SAVE</p>
            </Button>
          )}
          {props.isDetailSrModal && props.isWaitApproved && (
            <Button
              type='submit'
              color='#6DB200'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_approve_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleApprove}
            >
              <img
                style={{ marginRight: '10px', width: '18px', height: '18px' }}
                src={Images.check}
              />
              APPROVE
            </Button>
          )}
          {props.isDetailSrModal && props.isWaitApproved && (
            <Button
              type='submit'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_revise_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                backgroundColor: '#FF9B00',
                borderRadius: '5px',
              }}
              onClick={props.handleRevise}
            >
              <RestorePageIcon style={{ marginRight: '10px', color: 'white', fontSize: '20px' }} />
              <p style={{ marginRight: '10px' }}>REVISE</p>
            </Button>
          )}
          {props.isSrModal && props.isRevise && (
            <Button
              type='submit'
              color='primary'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_resubmit_btn'
              style={{ float: 'right', fontSize: FontSize.small, borderRadius: '5px' }}
              onClick={props.handleSave}
            >
              <DoneAllIcon
                style={{
                  marginLeft: '10px',
                  marginRight: '10px',
                  color: 'white',
                  fontSize: '18px',
                }}
              />
              <p style={{ marginRight: '10px' }}>RE-SUBMIT</p>
            </Button>
          )}
          {props.isDetailSrModal && props.isWaitApproved && (
            <Button
              type='submit'
              color='red'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_reject_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handleReject}
            >
              <img
                style={{ marginRight: '10px', width: '18px', height: '18px' }}
                src={Images.remove}
              />
              <p style={{ marginRight: '10px' }}>REJECT</p>
            </Button>
          )}
          {props.add && !props.isApproved && !props.isWaitApproved && !props.isRevise && (
            <Button
              type='submit'
              color='primary'
              disabled={props.isSubmitButtonDisabled || false}
              id='mdl_dialog_save_btn'
              style={{ float: 'right', fontSize: FontSize.small, borderRadius: '5px' }}
              onClick={props.handleSave}
            >
              <DoneAllIcon style={{ marginRight: '10px', color: 'white', fontSize: '18px' }} />
              {props.isSrModal ? 'SUBMIT' : 'SAVE'}
            </Button>
          )}
          {props.isPrintOut && (
            <Button
              type='submit'
              color='blue'
              id='mdl_dialog_print_btn'
              style={{
                float: 'right',
                marginRight: 10,
                fontSize: FontSize.small,
                borderRadius: '5px',
              }}
              onClick={props.handlePreview}
            >
              <PrintOutlined style={{ marginRight: 10 }} />
              PRINT PREVIEW
            </Button>
          )}
          {props.isSettlementPage && (
            <>
              {props.isEdit ? (
                <>
                  {props.isResubmitSettlement && props.showUnfinalizedButton ? (
                    <Button
                      type='submit'
                      color='primary'
                      id='mdl_dialog_finalized_btn'
                      style={{ float: 'right', marginLeft: '8px', fontSize: FontSize.small, borderRadius: '5px' }}
                      onClick={props.handleSubmit}
                    >
                      <DoneAllIcon
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: 'white',
                          fontSize: '18px',
                        }}
                      />
                      <p style={{ marginRight: '10px' }}>RE-SUBMIT</p>
                    </Button>
                  ) : props.isFinalizedSettlement && props.showUnfinalizedButton ? (
                    <Button
                      type='submit'
                      color='primary'
                      id='mdl_dialog_finalized_btn'
                      style={{ float: 'right', marginLeft: '8px', fontSize: FontSize.small, borderRadius: '5px' }}
                      onClick={props.handleSubmit}
                    >
                      FINALIZED
                    </Button>
                  ) : (
                    <Button
                      type='submit'
                      color='primary'
                      id='mdl_dialog_submit_btn'
                      style={{ float: 'right', fontSize: FontSize.small, borderRadius: '5px' }}
                      onClick={props.handleSubmit}
                    >
                      <DoneAllIcon
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: 'white',
                          fontSize: '18px',
                        }}
                      />
                      <p style={{ marginRight: '10px' }}>{props.isReviseSettlement ? 'RE-SUBMIT' : 'SUBMIT'}</p>
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {props.canConfirm && (
                    <>
                      <Button
                        type='submit'
                        color='#6DB200'
                        id='mdl_dialog_confirm_btn'
                        style={{ borderRadius:'5px', float: 'right', marginRight: 10, fontSize: FontSize.small }}
                        onClick={props.handleConfirm}
                      >
                        <div
                          style={{
                            display: 'flex',
                            height: '23px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            style={{ marginRight: '5px', width: '18px', height: '18px' }}
                            src={Images.check}
                          />
                          <p style={{ fontWeight:'bold' }}>APPROVE</p>
                        </div>{' '}
                      </Button>
                      <Button
                        type='submit'
                        color='orange'
                        id='mdl_dialog_revise_btn'
                        style={{ borderRadius:'5px', marginRight: 10, float: 'right', fontSize: FontSize.small }}
                        onClick={props.handleRevise}
                      >
                        <div
                          style={{
                            display: 'flex',
                            height: '23px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <RestorePageIcon style={{ color: 'white', fontSize: '20px' }} />
                          <p style={{ marginLeft: '5px', fontWeight:'bold' }}>REVISE</p>
                        </div>
                      </Button>
                    </>
                  )}
                  {props.allowPrint && (
                    <ButtonPrint onClick={props.handlePrint} id='mdl_dialog-print-btn'>
                      <div>
                        <PrintOutlined />
                      </div>
                      <div style={{ marginLeft: '5px' }}> Print</div>
                    </ButtonPrint>
                    // <Button
                    //   type='submit'
                    //   color='blue'
                    //   id='mdl_dialog_print_btn'
                    //   style={{ float: 'right', marginRight: 10, fontSize: FontSize.small }}
                    //   onClick={props.handlePrint}
                    // >
                    //   <PrintOutlined style={{ marginRight: 10 }} />
                    //   PRINT
                    // </Button>
                  )}
                  {(props.isUnfinalizedsettlement && props.showUnfinalizedButton) && (
                    <ButtonUnfinalized>
                      <Button
                        color={Colors.white}
                        id='mdl_dialog_unfinalized_btn'
                        backgroundColor={Colors.softBlue}
                        onClick={() => props.setUnfinalized(!props.unfinalized)}
                        style={{
                          float: 'right',
                          width: '152px',
                          height: '40px',
                          marginLeft: 10,
                          borderRadius: '5px',
                          fontSize: FontSize.medium,
                          backgroundColor: '#FF9B00',
                        }}
                      >
                        UNFINALIZED <IconButtonUnfinalized src={Images.dots} />
                      </Button>
                      <PopoupUnfinalized className={props.unfinalized ? 'show' : ''}>
                        <UnfinalizedButton onClick={() => {
                          props.setWantUnfinalized(true)
                          props.setUnfinalizedText('With Approval')
                        }}>
                          <UnfinalizedWApprovalIcon />
                          <div style={{ marginLeft: '8px' }}>
                            <UnfinalizedButtonTitle>With Approval</UnfinalizedButtonTitle>
                            <UnfinalizedButtonSubitle>Changes will reset your current approval</UnfinalizedButtonSubitle>
                          </div>
                        </UnfinalizedButton>
                        <UnfinalizedButton onClick={() => {
                          props.setWantUnfinalized(true)
                          props.setUnfinalizedText('Without Approval')
                        }}>
                          <UnfinalizedWOApprovalIcon />
                          <div style={{ marginLeft: '8px' }}>
                            <UnfinalizedButtonTitle>Without Approval</UnfinalizedButtonTitle>
                            <UnfinalizedButtonSubitle>Changes won’t impact your approval</UnfinalizedButtonSubitle>
                          </div>
                        </UnfinalizedButton>
                      </PopoupUnfinalized>
                    </ButtonUnfinalized>
                  )}
                  {(props.isResubmitSettlement && props.showUnfinalizedButton) ? (
                    <Button
                      type='submit'
                      color='primary'
                      id='mdl_dialog_resubmit_btn'
                      style={{ float: 'right', marginLeft: '8px', fontSize: FontSize.small, borderRadius: '5px' }}
                      onClick={props.handleSubmit}
                    >
                      <DoneAllIcon
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: 'white',
                          fontSize: '18px',
                        }}
                      />
                      <p style={{ marginRight: '10px' }}>RE-SUBMIT</p>
                    </Button>
                  ) : (props.isFinalizedSettlement && props.showUnfinalizedButton) && (
                    <Button
                      type='submit'
                      color='primary'
                      id='mdl_dialog_finalized_btn'
                      style={{ float: 'right', marginLeft: '8px', fontSize: FontSize.small, borderRadius: '5px' }}
                      onClick={props.handleSubmit}
                    >
                      FINALIZED
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </VesselContainer>
      )}
      {props.isStatic ? (
        <FormContainerStatic>{props.children}</FormContainerStatic>
      ) : (
        <FormContainer>{props.children}</FormContainer>
      )}
    </>
  )

  const _renderLoading = () => (
    <div
      id='master-modal-loading'
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 732 }}
    >
      <Loading />
    </div>
  )
  return (
    <DialogStyle
      id='mdl_comp_dialog'
      open={props.showModal}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth || false}
      onClose={props.handleClose}
      disableBackdropClick={true}
      aria-labelledby='customized-dialog-title'
      width={props.width || 40}
      height={props.height}
      heightType={props.heightType}
      disableEnforceFocus
      // disableEscapeKeyDown
    >
      <Header id='mdl_comp_dialog_header' whiteheader={props.whiteheader}>
        <TitleContainer>
          <DivTitle>
            {props.isDetail ? null : <DotIcon htmlColor={Colors.green} />}
            <Title id='modal-title' whiteheader={props.whiteheader}>{props.title}</Title>
          </DivTitle>
          <div id='modal-button' style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={props.handleClose} aria-label='close' id='mdl_dialog_x_btn'>
              <CloseIcon htmlColor={props.whiteheader ? Colors.black :Colors.pureWhite} />
            </IconButton>
          </div>
        </TitleContainer>
      </Header>
      {utilizeLoading && !isLoading
        ? _renderMainComponent()
        : utilizeLoading && isLoading
          ? _renderLoading()
          : _renderMainComponent()}
    </DialogStyle>
  )
}

MasterModal.defaultProps = {
  hasSubtitle: true,
  isExecutionSR: false
}

export default MasterModal
