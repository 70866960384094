import styled from 'styled-components'

import { Colors, FontSize } from '../../styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  flex-direction: row;
`
export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    height:14px;
`
export const Title = styled.p`
    line-height: 5px;
    color: ${Colors.grey};
    font-size: ${FontSize.extraSmall}px;
`