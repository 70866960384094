import { useState } from 'react'

import { Colors } from '~/styles'
import { Images } from '~/assets'

import Button from '../button'
import CancelSaveButtons from './cancel-save-button.component'
import {
  Title,
  Modal,
  Content,
  Wrapper,
  TextArea,
  IconClose,
  Paragraph,
  Description,
  ContainerConfirmation,
} from './modal-unfinalized.styled'
import { SavedAsSubmit } from '../svg'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const ModalUnfinalized = (props) => {
  const [reason, setReason] = useState('')

  const buttonClose = {
    position: 'absolute',
    right: '24px',
    top: '24px',
    color: (theme) => theme.palette.grey[500],
  }

  return (
    <>
      <Modal
        maxWidth='lg'
        disableEscapeKeyDown={true}
        id='mdl_unfinalized_reason_box'
        open={props.wantToUnfinalized}
        onClose={() => props.handleWantToUnfinalized(false)}
      >
        <Wrapper>
          <Title>Unfinalized ({props.unfinalizedText})</Title>
          <IconButton
            aria-label='close'
            style={buttonClose}
            onClick={() => props.handleWantToUnfinalized(false)}
          >
            <CloseIcon />
          </IconButton>
          <Content>
            <Description>Input your remarks to the field below.</Description>
            <TextArea
              multiline
              rows={10}
              value={reason}
              variant='outlined'
              error={reason.length < 3}
              id='modal_revise_reason_box_textarea'
              placeholder='Describe your revision here...'
              onChange={(event) => setReason(event.target.value)}
              helperText={reason.length < 3 && 'Required and must be longer than 3 characters'}
            />
          </Content>
          <CancelSaveButtons
            saveText='Send'
            cancelText='Cancel'
            disableSave={reason.length < 3}
            onCancel={() => {
              setReason('')
              props.handleWantToUnfinalized(false)
            }}
            saveId='modal_revise_reason_box_save_btn'
            cancelId='modal_revise_reason_box_cancel_btn'
            onSave={() => props.handleUnfinalized(reason)}
          />
          <IconClose
            src={Images.close}
            id='mdl_revise_reason_box_btn_close'
            onClick={() => {
              setReason('')
              props.handleWantToUnfinalized(false)
            }}
          />
        </Wrapper>
      </Modal>

      <Modal
        maxWidth='md'
        id='mdl_unfinalized_alert'
        disableEscapeKeyDown={true}
        open={props.showModalConfirmation}
        onClose={props.handleCloseConfirmation}
      >
        <ContainerConfirmation>
          <IconButton
            aria-label='close'
            style={buttonClose}
            onClick={() => {
              setReason('')
              props.handleCloseConfirmation()
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ marginTop: '34px' }}>
            <SavedAsSubmit />
          </div>
          <h3 style={{ fontSize: '24px', marginTop: '24px', marginBottom: 0 }}>Your service request has been unfinalized</h3>
          <Paragraph>Changes will reset your current approval</Paragraph>
          <Button
            color={Colors.white}
            style={{
              padding: 20,
              height: 48,
              width: 248,
              borderRadius: 6,
              fontSize: '18px',
              backgroundColor: Colors.softBlue
            }}
            onClick={() => {
              setReason('')
              props.handleCloseConfirmation()
            }}
          >
            Close this window
          </Button>
        </ContainerConfirmation>
      </Modal>
    </>
  )
}

export default ModalUnfinalized