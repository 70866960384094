import React from 'react'

import {
  Approve,
  DialogCon,
  TitleDelete,
  QuestionText,
  TitleWrapper,
  DialogWrapper,
  ButtonWrapper,
  ButtonTitleWrapper,
} from './modal-approve.styled.component'
import { Images } from '../../assets'
import Button from '../button'

const ModalApprove = ({ approveText, toggleDialog, handleApprove, titleText, isClicked }) => {
  return (
    <DialogWrapper style={{ borderRadius: 5 }}>
      <DialogCon>
        <TitleDelete>
          <TitleWrapper>{titleText ? titleText : 'Approve!'}</TitleWrapper>
          <div>
            <Approve src={Images.approve}/>
          </div>
        </TitleDelete>
      </DialogCon>
      <ButtonTitleWrapper>
        <QuestionText>{approveText}</QuestionText>
        <div>
          <ButtonWrapper>
            <Button color='grey' id='approve_exit' onClick={toggleDialog} type='confirmation'>
              No
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              color='green'
              id='m_approve_submit'
              type='confirmation'
              disable={isClicked ? true : false}
              onClick={() => {
                handleApprove()
              }}
            >
              Yes
            </Button>
          </ButtonWrapper>
        </div>
      </ButtonTitleWrapper>
    </DialogWrapper>
  )
}
export default ModalApprove
