import styled from 'styled-components'

export const ButtonDetail = styled.button`
    display: flex;
    border: 1px solid rgb(0, 130, 173);
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    height: 10px;
    outline: 0px;
    padding: 16px;
    font-weight: 600;
    border-radius: 6px;
    color: rgb(255, 255, 255);
    transition: all 0.3s ease-in-out 0s;
    background: rgb(0, 130, 173);
    cursor: pointer;
    width:100%;
    margin-bottom: ${p=>p.isCCU ? 0 :18}px;
`