import { Title, TitleMenu } from '../../styles/global.styled'
import { ButtonAddContainer } from './master-header.styled'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const MasterHeader = (props) => {
  return (
    <TitleMenu>
      <Title>{props.title}</Title>
      {props.allowCreate && (
        <ButtonAddContainer id='m-add-new-btn' onClick={props.onClick}>
          <AddCircleOutlineIcon />
          &nbsp;{props.buttonTitle}
        </ButtonAddContainer>
      )}
    </TitleMenu>
  )
}

export default MasterHeader
