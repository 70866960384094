import _ from 'lodash'
import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Srno,
  AddText,
  PlusIcon,
  DescText,
  DragIcon,
  HeadTitle,
  ErrorIcon,
  Container,
  TopWrapper,
  AddWrapper,
  SaveButton,
  HeadWrapper,
  DropDownItem,
  CrossIconRed,
  CrossIconHead,
  DropDownSelect,
  DropDownWrapper,
  DropDownContainer
} from './column-option.styled'

const ColumnOption = ({
  updateApi,
  dataState,
  fetchData,
  setShowColumnOption,
  parentActiveColumns,
  setGridColumnLoading,
  parentInactiveColumns
}) => {
  const [validColumns, setValidColumns] = useState(true)
  const [activeColumns, setActiveColumns] = useState(parentActiveColumns)
  const [inactiveColumns, setInactiveColumns] = useState(parentInactiveColumns)

  const pushChange = async () => {
    setGridColumnLoading(true)
    const inactive = inactiveColumns.map((item) => ({
      ...item,
      isActive: false
    }))
    const active = activeColumns.map((item) => ({
      ...item,
      isActive: true
    }))
    const combined = [...active, ...inactive]
    const result = combined.map((item, index) => ({
      Id: item.id,
      Code: item.code,
      Column_Name: item.columnName,
      Column_Description: item.columnDesc,
      Is_Active: item.isActive,
      Sort_No: index
    }))
    try {
      const response = await updateApi(result)
      if (response.status === 200) {
        fetchData(dataState)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const items = reorder(
      activeColumns,
      result.source.index,
      result.destination.index
    )

    setActiveColumns(items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const addColumn = () => {
    setValidColumns(false)
    setActiveColumns([
      ...activeColumns,
      {
        id: '',
        columnName: 'new',
        columnDesc: '        ',
        sortNo: '',
        isActive: ''
      }
    ])
  }

  const removeColumn = (id) => {
    let found = {}
    let filtered = []
    activeColumns.forEach((item) => {
      if (item.id === id) {
        found = item
      } else {
        filtered.push(item)
      }
    })
    setValidColumns(validateColumns(filtered))
    setActiveColumns(filtered)
    setInactiveColumns([...inactiveColumns, found])
  }

  const handleChange = (event, item, index) => {
    const found = inactiveColumns.find(
      (item) => item.columnName === event.target.value
    )
    const changeActive = [...activeColumns]
    changeActive[index] = found
    const changeInactive = [
      ...inactiveColumns.filter((val) => val.columnName !== found.columnName),
      item
    ].filter((val) => val.columnName !== 'new')
    setValidColumns(validateColumns(changeActive))
    setActiveColumns(changeActive)
    setInactiveColumns(changeInactive)
  }

  const validateColumns = (columns) => {
    const found = columns.filter((item) => item.id === '')
    return found !== []
  }

  const renderItems = (items) => {
    return items.map((item, index) => {
      if (item.columnName !== 'sr_no') {
        return (
          <Draggable
            draggableId={`item-${item.id}`}
            index={index}
            key={item.id}
            id={`col_opt-draggable-${item.id}`}
          >
            {(provided) => (
              <DropDownWrapper
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <DragIcon />
                <DropDownSelect
                  id={`col_opt-dropdown-${item.id}`}
                  defaultValue={item.columnName}
                  variant='outlined'
                  onChange={(event) => handleChange(event, item, index)}
                >
                  <DropDownItem value={item.columnName}>
                    {item.columnDesc}
                  </DropDownItem>
                  {_.orderBy(inactiveColumns, ['columnDesc'], ['asc']).map(
                    (val) => (
                      <DropDownItem key={val.id} value={val.columnName}>
                        {val.columnDesc}
                      </DropDownItem>
                    )
                  )}
                </DropDownSelect>
                {
                  <CrossIconRed
                    onClick={() => removeColumn(item.id)}
                    id={`col_opt-remove-column-btn-${item.id}`}
                  />
                }
              </DropDownWrapper>
            )}
          </Draggable>
        )
      } else {
        return (
          <Srno key={item.id}>
            <span>SR. No</span>
            <Tooltip title='This field cannot be removed.' placement='top'>
              <ErrorIcon />
            </Tooltip>
          </Srno>
        )
      }
    })
  }

  return (
    <Container>
      <TopWrapper>
        <HeadWrapper>
          <HeadTitle>Column Option</HeadTitle>
          <CrossIconHead
            onClick={() => setShowColumnOption(false)}
            id='col_opt-close-btn'
          />
        </HeadWrapper>
        <DescText>
          Add and remove columns. To change the column order drag and drop a
          field
        </DescText>
      </TopWrapper>
      <DropDownContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderItems(activeColumns)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DropDownContainer>
      <AddWrapper onClick={() => addColumn()} id='col_opt-add-column-btn'>
        <PlusIcon />
        <AddText>Add a Column</AddText>
      </AddWrapper>
      <SaveButton
        id='col_opt-save-btn'
        disable={!validColumns}
        onClick={() => {
          pushChange()
          setShowColumnOption(false)
        }}
      >
        SAVE
      </SaveButton>
    </Container>
  )
}

export default ColumnOption
