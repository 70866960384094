import 'react-step-progress-bar/styles.css'
import { makeStyles } from '@material-ui/core/styles'
import { ProgressBar, Step } from 'react-step-progress-bar'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

import { ProgressGrey } from '../../components/svg'
import { Images } from '../../assets'

const useStyles = makeStyles(() => ({
  outer: {
    backgroundColor: 'white',
    padding: '20px 80px',
    boxShadow: '0 1px 2px #80808080',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    fontSize: 45,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '120px',
    paddingBottom: '10px',
  },
  text: {
    fontSize: 12,
    padding: '10px 0',
    fontWeight: 'bold',
  },
  containerBottom: {
    padding: '0px 55px 0px 55px;',
  },
  transitionStep: {
    filter: 'grayscale(1)',
  },
  accomplished: {
    filter: 'grayscale(0)',
  },
  spanContainer: {
    fontSize: 25,
  },
}))

const ProgressContainer = ({ step }) => {
  const classes = useStyles()
  let percentage = 0
  let lowerCaseStep = step.toLowerCase() == 'allocated' ? 'approved' : step.toLowerCase()

  if (lowerCaseStep.toLowerCase() === 'draft') {
    percentage = 0
  }
  if (lowerCaseStep.toLowerCase() === 'waiting for approval') {
    percentage = 20
  }
  if (lowerCaseStep.toLowerCase() === 'approved' || lowerCaseStep.toLowerCase() === 'rejected' || lowerCaseStep.toLowerCase() === 'need to be revised') {
    percentage = 40
  }
  if (lowerCaseStep.toLowerCase() === 'canceled' || lowerCaseStep.toLowerCase() === 'in progress') {
    percentage = 60
  }
  if (lowerCaseStep.toLowerCase() === 'completed'
  || lowerCaseStep === 'pre finalized'
  || lowerCaseStep === 'completed without approval'
  || lowerCaseStep.toLowerCase() === 'waiting for approval 1'
  || lowerCaseStep.toLowerCase() === 'waiting for approval 2'
  || lowerCaseStep.toLowerCase() === 'waiting for approval 3'
  || lowerCaseStep.toLowerCase() === 'waiting for approval 4') {
    percentage = 80
  }
  if (lowerCaseStep.toLowerCase() === 'finalized') {
    percentage = 100
  }

  return (
    <div className={classes.outer}>
      <div className={classes.container}>
        <div className={classes.item}>
          <img src={percentage >= 0 ? Images.DraftGreen : Images.DraftGrey} />
          <span className={classes.text}>Draft</span>
        </div>
        <div className={classes.item}>
          <img
            src={
              percentage >= 20
                ? Images.WaitAppGreen
                : Images.WaitAppGrey
            }
          />
          <span className={classes.text}>Waiting Approval</span>
        </div>
        <div className={classes.item}>
          <img
            src={
              percentage >= 40 && (lowerCaseStep === 'approved'
              || lowerCaseStep === 'canceled'
              || lowerCaseStep === 'in progress'
              || lowerCaseStep === 'completed'
              || lowerCaseStep === 'finalized'
              || lowerCaseStep === 'pre finalized'
              || lowerCaseStep === 'completed without approval'
              || lowerCaseStep === 'waiting for approval 1'
              || lowerCaseStep === 'waiting for approval 2'
              || lowerCaseStep === 'waiting for approval 3'
              || lowerCaseStep === 'waiting for approval 4')
                ? Images.AppRejGreen
                : percentage >= 40 && (lowerCaseStep === 'rejected')
                  ? Images.AppRejRed
                  : percentage >= 40 && (lowerCaseStep === 'need to be revised')
                    ? Images.reviseRed
                    : Images.AppRejGrey

            }
          />{
            percentage >= 40 && (lowerCaseStep === 'approved'
            || lowerCaseStep === 'canceled'
            || lowerCaseStep === 'in progress'
            || lowerCaseStep === 'completed'
            || lowerCaseStep === 'finalized'
            || lowerCaseStep === 'pre finalized'
            || lowerCaseStep === 'completed without approval'
            || lowerCaseStep === 'waiting for approval 1'
            || lowerCaseStep === 'waiting for approval 2'
            || lowerCaseStep === 'waiting for approval 3'
            || lowerCaseStep === 'waiting for approval 4') ?
              <span className={classes.text}>Approved</span>
              :
              percentage >= 40 && (lowerCaseStep === 'rejected') ?
                <span className={classes.text}>Rejected</span>
                :
                percentage >= 40 && (lowerCaseStep === 'need to be revised') ?
                  <span className={classes.text}>Revised</span>
                  :
                  <span className={classes.text}>Approve/Reject</span>
          }
        </div>
        <div className={classes.item}>
          <img
            src={
              percentage >= 60 && (lowerCaseStep === 'in progress'
              || lowerCaseStep === 'completed'
              || lowerCaseStep === 'finalized'
              || lowerCaseStep === 'pre finalized'
              || lowerCaseStep === 'completed without approval'
              || lowerCaseStep === 'waiting for approval 1'
              || lowerCaseStep === 'waiting for approval 2'
              || lowerCaseStep === 'waiting for approval 3'
              || lowerCaseStep === 'waiting for approval 4')
                ? Images.ProgGreen
                : percentage >= 60 && (lowerCaseStep === 'canceled')
                  ? Images.BoatRed
                  : Images.ProgGrey
            }
          />
          {
            percentage >= 60 && (lowerCaseStep === 'in progress'
            || lowerCaseStep === 'completed'
            || lowerCaseStep === 'finalized'
            || lowerCaseStep === 'pre finalized'
            || lowerCaseStep === 'completed without approval'
            || lowerCaseStep === 'waiting for approval 1'
            || lowerCaseStep === 'waiting for approval 2'
            || lowerCaseStep === 'waiting for approval 3'
            || lowerCaseStep === 'waiting for approval 4') ?
              <span className={classes.text}>In Progress</span>
              :
              percentage >= 60 && (lowerCaseStep === 'canceled') ?
                <span className={classes.text}>Canceled</span>
                :
                <span className={classes.text}>In Progress/Cancel</span>
          }
        </div>
        <div className={classes.item}>
          <img src={percentage >= 80 ? Images.CompGreen : Images.CompGrey} />
          {
            percentage >= 80 && (lowerCaseStep == 'completed'
            || lowerCaseStep === 'finalized'
            || lowerCaseStep === 'pre finalized'
            || lowerCaseStep === 'completed without approval'
            || lowerCaseStep === 'waiting for approval 1'
            || lowerCaseStep === 'waiting for approval 2'
            || lowerCaseStep === 'waiting for approval 3'
            || lowerCaseStep === 'waiting for approval 4') ?
              <span className={classes.text}>Completed</span> : <span className={classes.text}>Complete</span>
          }
        </div>
        <div className={classes.item}>
          <img src={percentage >= 100 ? Images.FinalGreen : Images.FinalGrey} />
          {
            percentage >= 100 && (lowerCaseStep === 'finalized') ?
              <span className={classes.text}>Finalized</span> : <span className={classes.text}>Finalize</span>
          }
        </div>
      </div>
      <div className={classes.containerBottom}>
        <ProgressBar height='2px' filledBackground='#95C123' percent={percentage}>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {percentage >= 0 ? <CheckListIcon /> : <ProgressGrey />}
                </span>
              </div>
            )}
          </Step>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {percentage >= 20 ? (
                    <CheckListIcon />
                  ) : (
                    <ProgressGrey />
                  )}
                </span>
              </div>
            )}
          </Step>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {
                    percentage >= 40 &&
                  (lowerCaseStep === 'approved'
                  || lowerCaseStep === 'canceled'
                  || lowerCaseStep === 'in progress'
                  || lowerCaseStep === 'completed'
                  || lowerCaseStep === 'finalized'
                  || lowerCaseStep === 'pre finalized'
                  || lowerCaseStep === 'completed without approval'
                  || lowerCaseStep === 'waiting for approval 1'
                  || lowerCaseStep === 'waiting for approval 2'
                  || lowerCaseStep === 'waiting for approval 3'
                  || lowerCaseStep === 'waiting for approval 4') ? (
                        <CheckListIcon />
                      ) : (
                        percentage >= 40 &&
                      (lowerCaseStep === 'rejected') ? (
                            <CrossIcon />
                          ) :
                          percentage >= 40 &&
                      (lowerCaseStep === 'need to be revised') ? (
                              <CrossIcon />
                            ) : (
                              <ProgressGrey />
                            )
                      )}
                </span>
              </div>
            )}
          </Step>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {
                    percentage >= 60 &&
                    (lowerCaseStep === 'in progress'
                    || lowerCaseStep === 'completed'
                    || lowerCaseStep === 'finalized'
                    || lowerCaseStep === 'pre finalized'
                    || lowerCaseStep === 'completed without approval'
                    || lowerCaseStep === 'waiting for approval 1'
                    || lowerCaseStep === 'waiting for approval 2'
                    || lowerCaseStep === 'waiting for approval 3'
                    || lowerCaseStep === 'waiting for approval 4') ? (
                        <CheckListIcon />
                      ) : (
                        percentage >= 60 &&
                      (lowerCaseStep === 'canceled') ? (
                            <CrossIcon />
                          ) : (
                            <ProgressGrey />
                          )
                      )}
                </span>
              </div>
            )}
          </Step>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {percentage >= 80 ? <CheckListIcon /> : <ProgressGrey />}
                </span>
              </div>
            )}
          </Step>
          <Step transition='scale'>
            {() => (
              <div>
                <span className={classes.spanContainer}>
                  {percentage >= 100 ? <CheckListIcon /> : <ProgressGrey />}
                </span>
              </div>
            )}
          </Step>
        </ProgressBar>
      </div>
    </div>
  )
}

export default ProgressContainer

const CheckListIcon = (props) => {
  return (
    <CheckCircleRoundedIcon
      style={{ color: '#95C123', background: 'white', borderRadius: '50px' }}
      {...props}
    />
  )
}

const CrossIcon = (props) => {
  return (
    <CancelRoundedIcon
      style={{ color: 'red', background: 'white', borderRadius: '50px' }}
      {...props}
    />
  )
}
