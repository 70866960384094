import styled from 'styled-components'
import { Colors } from '../../styles'

export const WrapperButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: end;
    color: ${Colors.cancelTextColor};
`
export const IconButton = styled.img`
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    user-select: none;
    font-size: 1.5rem;
    fill: currentColor;
    margin-right: ${(p) => p.right == 0 ? p.right : 6}px;
    `