import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'

import {
  DialogCon,
  TitleDelete,
  QuestionText,
  TitleWrapper,
  DialogWrapper,
  ButtonWrapper,
  ButtonTitleWrapper,
  Form,
} from './modal-cancel.styled.component'
import Button from '../button'
import { Colors } from '../../styles'
import Input from '../input'

const ModalCancel = ({ deleteText, toggleDialog, ServiceRequestCancelForm, isClicked }) => {
  return (
    <DialogWrapper style={{ borderRadius: 5 }}>
      <DialogCon>
        <TitleDelete>
          <TitleWrapper>Cancel!</TitleWrapper>
          <div>
            <CancelIcon style={{ fontSize: '60px', color: Colors.deleteColor }} />
          </div>
        </TitleDelete>
      </DialogCon>
      <ButtonTitleWrapper>
        <QuestionText>{deleteText}</QuestionText>
        <Form>
          <Input
            form={ServiceRequestCancelForm}
            style={{ width: '200px' }}
            id='input-txt-cancel'
            label='Reason'
            name='CanceledRemarks'
            value={ServiceRequestCancelForm.values.CanceledRemarks}
          />
        </Form>
        <div>
          <ButtonWrapper>
            <Button color='grey' id='del_exit' onClick={toggleDialog} type='confirmation'>
              No
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              color='red'
              id='m_del_submit'
              type='confirmation'
              disable={isClicked ? true : false}
              onClick={ServiceRequestCancelForm.handleSubmit}
            >
              Yes, Confirm!
            </Button>
          </ButtonWrapper>
        </div>
      </ButtonTitleWrapper>
    </DialogWrapper>
  )
}
export default ModalCancel
