import { Title, TitleMenu } from '../../styles/global.styled'
import { ButtonAddContainer, PipelineContainer } from './service-request-header.styled'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import RemoveIcon from '@material-ui/icons/Remove'
const ServiceRequestHeader = (props) => {
  return (
    <TitleMenu>
      <Title>{props.title}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {props.allowCreate && (
          <>
            <ButtonAddContainer id='m-add-new-btn' onClick={props.onClickAdd}>
              <AddCircleOutlineIcon />
              &nbsp;{props.buttonAddTitle}
            </ButtonAddContainer>
            <PipelineContainer>
              <RemoveIcon />
            </PipelineContainer>
          </>
        )}
        <ButtonAddContainer id='m-add-option-btn' onClick={props.onClickColumn}>
          <ArtTrackIcon />
          &nbsp;{props.buttonColumnTitle}
        </ButtonAddContainer>
      </div>
    </TitleMenu>
  )
}

export default ServiceRequestHeader
