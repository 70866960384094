export default {
  red: '#e11313',
  grey: '#7b7b7b',
  white: '#ffffff',
  green: '#73b816',
  btnBlue:'#1890FF',
  black: '#000000',
  purple: '#374fc7',
  orange: '#F59F00',
  softRed: '#f03f3e',
  wrapGray: '#DDDDDD',
  softGrey: '#999999',
  softBlue: '#0082ad',
  newGreen: '#95c123',
  seaBlue: '#065f85',
  skyBlue: '#E3F2FF',
  hintGrey: '#C9C9C9',
  modalGrey: '#777777',
  transBlue: '#d5e9f1',
  softGreen: '#71af01',
  darkGreen: '#2B8A3E',
  softBlack: '#343434',
  aliceBlue: '#0091C1',
  dashGray : '#CEDEFF',
  pureWhite: '#f2f2f2',
  lightBlue: '#00C0FF',
  smootBlue: '#EBF6FF',
  borderGrey: '#e6e6e6',
  yellowWarn: '#eed202',
  darkBlue: '#004D67E5',
  cancelColor: '#cbcbcb',
  mediumGreen: '#8EC10A',
  deleteColor: '#d6362e',
  transparent: 'transparent',
  cancelTextColor: '#333333',
  backgroundColor: '#F4F6F9',
}
