import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import {
  DialogCon,
  TitleEvidence,
  QuestionText,
  TitleWrapper,
  DialogWrapperRevise,
  ButtonWrapper,
  ButtonTitleWrapper,
  LoadingContainer,
  Form,
  CenterDivFlex,
  CenterDivText,
} from './modal-evidence.styled.component'
import Input from '../input'

import Button from '../button'

import { Colors } from '../../styles'
import { Loading } from '../loading/loading.styled'

const ModalEvidenceRevise = ({
  textEvidence,
  Submit,
  setAlert,
  isLoading,
  fileDetail,
  toggleDialog,
  fileEvidence,

  setFileEvidence,
  textEvidenceConfirmation,
  SettlementEvidenceForm,
  uploadingFileToServer,
  isClicked,
}) => {
  const fileHandler = (e) => {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].size > fileDetail.size * 1024 * 1024
    ) {
      setAlert({
        msg: `Failed. File size exceed ${fileDetail.size} Mb`,
        success: false,
        visible: true,
      })
      document.getElementById('formInputrevise').reset()
    } else {
      const formdataUpload = new FormData()
      formdataUpload.append('file', e.target.files[0])
      // setFileName()
      uploadingFileToServer(formdataUpload, (callback) => {
        setFileEvidence(callback.data.data)

        // submit ur code here, use callback properly
      })
    }
  }
  const fileUploaded = fileEvidence && fileEvidence.length > 0
  return (
    <DialogWrapperRevise style={{ borderRadius: 5 }}>
      <DialogCon>
        <TitleEvidence>
          <TitleWrapper>Evidence</TitleWrapper>
          <CloudUploadIcon style={{ fontSize: '60px', color: Colors.evidenceColor }} />
        </TitleEvidence>
      </DialogCon>
      <ButtonTitleWrapper>
        <div
          id='formInputrevise'
          style={{
            margin: '10px',
          }}
        >
          <QuestionText style={{ marginBottom: '15px' }}>{textEvidenceConfirmation}</QuestionText>
          <Form>
            <Input
              style={{ width: '280px' }}
              form={SettlementEvidenceForm}
              value={SettlementEvidenceForm.values.RejectRemarks}
              id='input-txt-reject'
              label='Remarks'
              name='RejectRemarks'
              multiline={true}
              rowsMax={2}
              variant='outlined'
            />
          </Form>
          {fileEvidence.length <= 0 && <QuestionText>{textEvidence}</QuestionText>}
          {isLoading && (
            <LoadingContainer>
              <Loading id='sr-doc_mdl-loading-indicator' />
            </LoadingContainer>
          )}
          <CenterDivText>
            {fileEvidence && fileEvidence.length > 0
              ? fileEvidence[0].FileName.length > 38
                ? fileEvidence[0].FileName.slice(0, 35) + '...'
                : fileEvidence[0].FileName
              : ''}
          </CenterDivText>

          <CenterDivFlex>
            <input
              accept={fileDetail.type}
              type='file'
              style={{
                width: '90px',
                margin: '10px',
              }}
              name='Ship_Particular_File'
              id='m_vessel_form_shp_tf'
              onChange={(event) => {
                fileHandler(event)
              }}
              tabIndex={20}
            />
          </CenterDivFlex>
        </div>

        <div>
          <ButtonWrapper>
            <Button color='grey' id='del_exit' onClick={toggleDialog} type='confirmation'>
              Cancel
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              color='red'
              id='m_del_submit'
              disabled={!fileUploaded || (isClicked ? true : false)}
              style={{
                opacity: fileUploaded ? '100%' : '50%',
                cursor: fileUploaded ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                SettlementEvidenceForm.handleSubmit()
              }}
              type='confirmation'
            >
              {Submit}
            </Button>
          </ButtonWrapper>
        </div>
      </ButtonTitleWrapper>
    </DialogWrapperRevise>
  )
}

export default ModalEvidenceRevise
