import styled from 'styled-components'
import Badge from '@material-ui/core/Badge'

import { Colors } from '../../styles'

export const HeaderWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 7.17vh;
  position: fixed;
  background: ${Colors.softBlue};
  border-bottom: 0.409vh ${Colors.softGreen} solid;
`

export const Dropdown = styled.div`
  min-width: 110px;
  max-height: 0;
  overflow: hidden;
  padding-top: 2vh;
  transition: max-height 0.2s ease-in-out;
`
export const DropdownList = styled.div`
  width: ${(p) => (p.type === 'Report' ? '230px' : '12.29vh')};
  text-align: left;
  padding: 2px 10px;
  color: ${Colors.softBlue};
  background: ${Colors.pureWhite};
  border: 1px solid ${Colors.borderGrey};

  &:hover {
    color: ${Colors.pureWhite};
    background: transparent linear-gradient(180deg, #166a8a 0%, #1091bf 100%) 0% 0% no-repeat
      padding-box;
  }
`
export const WrapperDropdown = styled.div`
  width: 5.7vw;
  cursor: pointer;
  margin: auto 5px;
  line-height: 2.45vh;
  height: 2.86vh;
  text-align: center;
  border-radius: 50px;
  white-space: nowrap;
  color: ${Colors.pureWhite};
  background: ${(p) => (p.active ? Colors.softGreen : Colors.softBlue)};

  &:hover ${Dropdown} {
    max-height: 400px;
  }
`
export const MiddleMenu = styled.div`
  cursor: pointer;
  overflow: hidden;
  margin: auto 5px;
  line-height: 2.45vh;
  height: 2.86vh;
  padding: 0px 20px;
  text-align: center;
  border-radius: 50px;
  white-space: nowrap;
  color: ${Colors.pureWhite};
  background: ${(p) => (p.active ? Colors.softGreen : Colors.softBlue)};
`

export const RightMenu = styled.div`
  min-width: ${(p) => p.minWidth};
  display: flex;
  align-items: center;
  color: ${Colors.pureWhite};
  justify-content: space-between;
  margin-right: 40px;
`
export const IconButtonWrapper = styled.div`
  height: 5vh;
  line-height: 6vh;
  cursor: pointer;
  margin: 0px 10px;
`

export const ProfileButtonWrapper = styled.div`
  padding: 5px 10px;
  line-height: 2vh;
  height: 5vh;
  border-radius: 8px;
  // border: 1px solid ${Colors.pureWhite};
  margin: 0px 10px 0px 0px;
  direction: rtl
`
export const LogoutDialogWrapper = styled.div`
  width: 304px;
  height: 351px;
  display: flex;
`

export const Row = styled.div`
  height: 7.17vh;
  display: flex;
  line-height: 6.14vh;
  color: ${Colors.grey};
  justify-content: space-between;
`

export const Column = styled.div`
  flex: 1;
  height: 7.17vh;
  display: flex;
  padding: 0px 0px;
  justify-content: center;
`

export const Content = styled.div`
  padding-top: 7.17vh;
`

export const HomeWrapper = styled.div`
  width: 3.9vw;
  height: 7.17vh;
`

export const Home = styled.div`
  cursor: pointer;
  padding: 0.819vh 0;
  text-align: center;
  line-height: 6.14vh;
  color: ${Colors.pureWhite};
  background: ${Colors.aliceBlue};
  height: 95%;
`
export const Icon = styled.div`
  height: 1vh;
`
export const TextIcon = styled.div`
  height: 4.1vh;
  font-size: 1.22vh;
  font-weight: bold;
  letter-spacing: 1.2px;
  padding: 0.5vh 0 0 0;
`

export const LintasWrapper = styled.div`
  margin: 1vh;
`
export const LayoutBackground = styled.div`
  height: 100vh;
  background: ${Colors.backgroundColor};
  overflow: auto;
`
export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 2vh;
    min-width: 2vh;
    padding: 0;
    border-radius: 1vh;

  }
`

export const UserInfo = styled.div`
  margin-left: 10px;
`
export const UserInfoText = styled.div`
  display: grid;
  justify-items: left;
  font-size: 1vmin;
`