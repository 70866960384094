import { useState } from 'react'
import PropTypes from 'prop-types'
import { InfoBox, Marker } from '@react-google-maps/api'

import { TooltipContainer, TooltipItem } from './maps.styled'

const MapsMarker = (props) => {
  const { id, position, title='', subtitle, icon, disableTooltip, srNo, onClick, colorIndicator } = props
  const [isTooltipHover, setIsTooltipHover] = useState(false)

  return (
    <Marker
      position={position}
      onClick={onClick}
      icon={icon}
      onMouseOver={() => setIsTooltipHover(true)}
      onMouseOut={() => setIsTooltipHover(false)}
    >
      <span onClick={onClick} id={id} className='dashboard-marker-icon-representation'>
        {colorIndicator ? colorIndicator : 'user-location'}
      </span>
      {!disableTooltip && isTooltipHover && (
        <InfoBox position={position} options={{ closeBoxURL: '' }}>
          <TooltipContainer
            bigger={!!srNo}
            colorIndicator={colorIndicator}
          >
            <TooltipItem bold>
              {title.toUpperCase()}
            </TooltipItem>
            <TooltipItem>
              {subtitle}
            </TooltipItem>
            {srNo && (
              <TooltipItem>
                {srNo}
              </TooltipItem>
            )}
          </TooltipContainer>
        </InfoBox>
      )}
    </Marker>
  )
}

MapsMarker.propTypes = {
  srNo: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  disableTooltip: PropTypes.bool,
  id: PropTypes.string.isRequired,
  colorIndicator: PropTypes.string,
  position: PropTypes.object.isRequired,
}

export default MapsMarker
