import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import TextField from '@material-ui/core/TextField'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

const InputDate = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        autoOk={true}
        ampm={false}
        disabled={props.readOnly}
        variant='inline'
        format='dd/MM/yyyy'
        TextFieldComponent={TextField}
        value={props.form.values[props.name] || props.value || null}
        onChange={(v) => props.form.setFieldValue(props.name, v)}
        onBlur={() => props.form.setFieldTouched(props.name, true)}
        error={
          props.form.touched[props.name] && !!props.form.errors[props.name]
        }
        helperText={
          props.form.touched[props.name] && props.form.errors[props.name]
            ? props.form.errors[props.name]
            : ' '
        }
        InputProps={{
          inputProps: {
            disabled: !!props.readOnly
          }
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

InputDate.defaultProps = {
  readOnly: false
}

InputDate.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default InputDate
