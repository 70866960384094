import {
  Paginate,
  DropDownItem,
  SpanRightText,
  PagerContainer,
  RightContainer,
  DropDownSelect,
} from './pager.styled'

const Pager = (props) => {
  const currentPage = Math.floor(props.skip / props.take) + 1
  const totalPages = Math.ceil((props.total || 0) / props.take)

  return (
    <PagerContainer>
      <div>
        <DropDownSelect
          id='m_grid-pager'
          value={props.take}
          variant='outlined'
          onChange={(e) => {
            props.onPageChange({
              target: null,
              skip: 0,
              take: e.target.value,
              syntheticEvent: e,
              nativeEvent: e.nativeEvent,
            })
          }}
        >
          {props.pageSizes.map((item, index) => (
            <DropDownItem value={item} key={index} id={`m_grid-pager-item-${index}`}>
              {item}
            </DropDownItem>
          ))}
        </DropDownSelect>
        <span>items per page</span>
      </div>
      <RightContainer>
        <Paginate
          size='small'
          showLastButton
          showFirstButton
          count={totalPages}
          page={currentPage}
          id='m_grid-pagination'
          onChange={(e, p) => {
            props.onPageChange({
              target: null,
              skip: (p - 1) * props.take,
              take: props.take,
              syntheticEvent: e,
              nativeEvent: e.nativeEvent,
            })
          }}
        />
        <SpanRightText>{`${props.skip + 1} - ${
          props.skip + props.take < props.total ? props.skip + props.take : props.total
        } of ${props.total} items`}</SpanRightText>
      </RightContainer>
    </PagerContainer>
  )
}

export default Pager
