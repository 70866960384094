import styled from 'styled-components'

// import { Colors } from '../../styles'

export const ButtonAddContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
`

export const PipelineContainer = styled.div`
  display: flex;
  transform: rotate(90deg);
  font-size: 20px;
  align-items: center;
  justify-content: space-between;
`
