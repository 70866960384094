import React from 'react'
import moment from 'moment'
import Timeline from '@material-ui/lab/Timeline'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import { createMuiTheme } from '@material-ui/core/styles'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { ProgressGrey } from '../../components/svg'
import { ConvertLocalTimeGet } from '../../utils'

const Theme = createMuiTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          display: 'none'
        }
      }
    }
  }
})

const useStyles = makeStyles(() => ({
  noRecords: {
    margin: 'auto',
    height: 'inherit',
    overflowY: 'auto',
    paddingTop: '20rem',
    borderTop: 'outset'
    // background: '#F6F9FC',
  },
  timeline: {
    margin: 'auto',
    paddingTop: '50px',
    borderTop: 'outset',
    // background: '#F6F9FC',
    height: 'auto'
  },
  outer: {
    display: 'flex'
  },
  timelineIconGreen: {
    border: 'none',
    boxShadow: 'none',
    paddingTop: '3.7px',
    background: '#95C123'
  },
  timelineIcon: {
    border: 'none',
    boxShadow: 'none'
  },
  history: {
    maxWidth: '75%',
    display: 'flex',
    marginRight: '10px',
    marginBottom: '20px'
  },
  delayTime: {
    color: '#343F54'
  },
  delay: {
    display: 'flex',
    paddingTop: '5px'
  },
  iconDelay: {
    fontSize: '1rem',
    color: '#D60005',
    paddingTop: '5px'
  },
  historyTime: {
    right: '20px',
    color: '#343F54',
    paddingTop: '5px',
    position: 'absolute'
  },
  timelineContent: {
    width: '90%'
  },
  contentHistory: {
    width: '100%',
    display: 'flex'
  },
  contentTimeRight: {
    right: '20px',
    margin: '10px 0px',
    paddingTop: '20px',
    color: '#3434349A',
    position: 'absolute'
  },
  missingOppositeContent: {
    display: 'flex',
    paddingLeft: '40px',
    minHeight: '90px',
    '&:before': {
      display: 'none'
    }
  }
}))

const ModalHistory = (props) => {
  const classes = useStyles()
  const { progressItem: dp } = props
  return (
    <>
      {dp.data === null ? (
        <h4 className={classes.noRecords} align='center'>
          No records available
        </h4>
      ) : (
        <Timeline className={classes.timeline}>
          {dp &&
            dp.data &&
            dp.data.map((mapDataHistory, idx) => {
              let now = moment()
              let time = moment(mapDataHistory.ActionTime)._i
              let plusOne = moment.duration(now.diff(time)).asMinutes()
              return (
                <TimelineItem
                  theme={Theme}
                  className={classes.missingOppositeContent}
                  key={idx}
                >
                  <TimelineSeparator>
                    {idx === 0 ? (
                      <TimelineDot
                        className={classes.timelineIconGreen}
                        color='inherit'
                      >
                        <ProgressGrey />
                      </TimelineDot>
                    ) : (
                      <TimelineDot
                        className={classes.timelineIcon}
                        color='inherit'
                      >
                        <ProgressGrey />
                      </TimelineDot>
                    )}
                    {idx !== dp.data.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className={classes.timelineContent}>
                    <div className={classes.contentHistory}>
                      <Typography
                        className={classes.history}
                        variant='h6'
                        component='h1'
                      >
                        {mapDataHistory.History}
                      </Typography>
                      {plusOne <= 1 ? (
                        <Typography className={classes.contentTimeRight}>
                          just now
                        </Typography>
                      ) : (
                        <div>
                          <Typography className={classes.historyTime}>
                            {moment(
                              ConvertLocalTimeGet(
                                props.data.Location,
                                mapDataHistory.ActionTime,
                                'YYYY/MM/DD HH:mm'
                              )
                            ).format('dddd, D MMM YYYY')}
                          </Typography>
                          <Typography className={classes.contentTimeRight}>
                            {moment(
                              ConvertLocalTimeGet(
                                props.data.Location,
                                mapDataHistory.ActionTime,
                                'YYYY/MM/DD HH:mm'
                              )
                            ).format('HH:mm')}
                            &nbsp;
                            {props.data.location_desc == 'Balikpapan' ||
                            props.data.Location == 'Kariangau'
                              ? 'WITA'
                              : props.data.Location == 'Surabaya' ||
                                props.data.Location == 'Gresik' ||
                                props.data.Location == 'Marunda'
                              ? 'WIB'
                              : props.data.Location == 'Sorong'
                              ? 'WIT'
                              : null}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {mapDataHistory.Status === 'Rejected' ||
                    mapDataHistory.Status === 'Canceled' ||
                    mapDataHistory.Status === 'Need to be Revised' ||
                    mapDataHistory.Remarks !== '' ? (
                      <div className={classes.delay}>
                        <FiberManualRecordIcon className={classes.iconDelay} />
                        &nbsp;&nbsp;{'Remarks :'}&nbsp;&nbsp;
                        {mapDataHistory.Remarks}&nbsp;
                      </div>
                    ) : null}
                    {mapDataHistory.Delay === null
                      ? null
                      : mapDataHistory.Delay.map((delayHistory, index) => {
                          return (
                            <div className={classes.delay} key={index}>
                              <FiberManualRecordIcon
                                className={classes.iconDelay}
                              />
                              &nbsp;&nbsp;{delayHistory.DelayType}&nbsp;-&nbsp;
                              {delayHistory.Delay},&nbsp;&nbsp;
                              <Typography className={classes.delayTime}>
                                {moment(delayHistory.StartDate).format('HH:mm')}
                              </Typography>
                              &nbsp;-&nbsp;
                              <Typography className={classes.delayTime}>
                                {moment(delayHistory.EndDate).format('HH:mm')}
                              </Typography>
                            </div>
                          )
                        })}
                  </TimelineContent>
                </TimelineItem>
              )
            })}
        </Timeline>
      )}
    </>
  )
}
export default ModalHistory
