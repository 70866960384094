/* eslint-disable no-unused-vars */
import { isNull, orderBy } from 'lodash'
import { useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Alerts from '../alert'
import Button from '../button'
import { Colors } from '../../styles'
import { Images } from '../../assets'
import { ExecutionAPI } from '../../api'

import {
  Title,
  Image,
  Header,
  DivTitle,
  TitleDoc,
  TitleIcon,
  ModalIcon,
  IconClose,
  IconCancel,
  CustomModal,
  TitleWrapper,
  DialogWrapper,
  WrapperBottom,
  ButtonWrapper,
  WrapperUpload,
  TitleContainer,
  WrapperDocument,
} from './modal-upload.styled'
import { ConvertLocalTimeGet } from '../../utils'

const ModalUpload = ({ showModal, handleClose, params, data, importCargo }) => {
  const ref = useRef('upload_file')

  const [documents, setDocument] = useState([])
  const [excelPath, setExcelPath] = useState('')
  const toggleModalInfo = () =>   setAlert({ msg: '', success: true, visible: false, type: '' })

  const [showAlert, setAlert] = useState({
    msg: '',
    type: '',
    success: '',
    visible: false,
  })

  const btnStyle = {
    width: 140,
    height: 48,
    padding: 20,
    fontSize : 18,
    marginLeft: 20,
    borderRadius: 6,
  }

  const triggerUpload = () => {
    if (ref.current !== undefined && ref.current.click !== undefined) {
      ref.current.click()
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        acceptedFiles.map((e) => {
          let parsedData = ''
          let fileSize = 5
          let formData = new FormData()
          let fileTypes = [ 'xls', 'xlsx' ]
          if (!fileTypes.includes(e.name.split('.')[1])) {
            parsedData = fileTypes.join(',').split(',').join(', .')
            setAlert({
              msg: `You can only upload file with extention .${parsedData}`,
              success: false,
              visible: true,
              type: 'update',
            })
          } else if (e.size > fileSize * 1024 * 1024) {
            setAlert({
              msg:  `One of the file size exceed ${fileSize} Mb`,
              success: false,
              visible: true,
              type: 'update',
            })
          }  else {
            formData.append('file', e)
            formData.append('action', 'UPLOAD_EXCEL')
            uploadExcel(formData, e)
          }
        })
      } else {
        setAlert({
          msg:  'You can only upload one file',
          success: false,
          visible: true,
          type: 'update',
        })
      }
    }
  })

  const uploadExcel = async (formData, e) => {
    if (formData.getAll('file').length > 0) {
      try {
        const response = await ExecutionAPI.UploadExcelCargo(formData)
        if (response && response) {
          setDocument([{
            filename : e.name,
            filesize : e.size,
            path : response.data.message.data,
          }])
        } else {
          setAlert({
            msg:  'Oops! Something went wrong. Please try again later.',
            success: false,
            visible: true,
            type: 'update',
          })
        }
      } catch (error) {
        return error
      }
    }
  }

  const exportToExcel = async () => {
    try {
      const formData = {
        sr_no : params.Sr_No,
        cargo_information : orderBy(data.map((cr) => {
          let data = { ...cr, is_deleted : cr.is_deleted ? 'TRUE' : 'FALSE' }
          data.material_date = ConvertLocalTimeGet(params.Location, cr.material_date)
          return data
        }), ['cargo_detail_id'], ['asc'])
      }
      const response = await ExecutionAPI.ExportExcelCargo(formData)
      if (response && response.data.message.data) {
        setExcelPath(response.data.message.data)
        document.getElementById(`download-excel-${params.Sr_No}`).click()
      }
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const importToExcel = async () => {
    try {
      const form = { 'URL' : documents[0].path, 'sr_no' : params.Sr_No  }
      const response = await ExecutionAPI.ImportExcelCargo(form)
      if (response && response.data.message.data) {
        const excel = response.data.message.data
        if (isNull(excel.ErrorExcel)) {
          importCargo(!isNull(excel.CargoInformation) ?
            excel.CargoInformation : [])
        } else {
          setAlert({
            msg: excel.ErrorExcel,
            success: false,
            visible: true,
            type: 'update',
          })
        }
      }
      handleClose()
      setDocument([])
    } catch (error) {
      return error
    }
  }

  return (
    <>
      <CustomModal
        maxWidth='md'
        open={showModal}
      >
        <DialogWrapper>

          <Header id='mdl_comp_dialog_header'>
            <TitleContainer>
              <DivTitle>
                <Title>Import or Export Cargo Data</Title>
              </DivTitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => {
                    handleClose()
                    setDocument([])
                  }}
                  aria-label='close'
                  id='mdl_dialog_x_btn'>
                  <CloseIcon htmlColor={Colors.pureWhite} />
                </IconButton>
              </div>
            </TitleContainer>
          </Header>

          <div>
            {documents.length === 0 ? (
              <WrapperBottom>
                <WrapperUpload
                  id='export-cargo'
                  onClick={exportToExcel}>
                  <ModalIcon src={Images.ExportIcon}/>
                  <TitleIcon>Download document template</TitleIcon>
                </WrapperUpload>
                <WrapperUpload
                  id='upload-document'
                  backgroundColor={Colors.skyBlue}
                  onClick={triggerUpload}
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <ModalIcon src={Images.ImportIcon}/>
                  <TitleIcon>Click upload your document</TitleIcon>
                  <input
                    hidden
                    type='file'
                    name='upload_file'
                    {...getInputProps()}
                  />
                </WrapperUpload>
              </WrapperBottom>
            ) : (
              <>
                <WrapperDocument>
                  <div style={{ display:'flex', alignItems: 'center' }}>
                    <Image src={Images.XlsIcon}/>
                    <div>
                      <TitleDoc>{documents[0].filename}</TitleDoc>
                      <TitleDoc
                        size={14}
                        lineHeight={1}
                        color={Colors.modalGrey}
                      >{(documents[0].filesize/1000).toFixed(1)} kb</TitleDoc>
                    </div>
                  </div>
                  <IconButton
                    onClick={() => {
                      setDocument([])
                    }}
                    aria-label='close'
                    id='reset-document'>
                    <CloseIcon htmlColor={'#333'} />
                  </IconButton>
                </WrapperDocument>
                <ButtonWrapper>
                  <Button
                    id='reset-document'
                    width={14.5}
                    border={true}
                    style={btnStyle}
                    color='transparent'
                    borderColor={Colors.wrapGray}
                    onClick={() => setDocument([])}
                  > Cancel
                  </Button>
                  <Button
                    id='import-cargo'
                    width={14.5}
                    style={btnStyle}
                    color={Colors.newGreen}
                    onClick={() => importToExcel()}
                  > Add
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </div>
        </DialogWrapper>
      </CustomModal>
      <a  href={excelPath}
        download
        style={{ display: 'none' }}
        target='_blank' rel='noopener noreferrer'
        id={`download-excel-${params.Sr_No}`}
      >download</a>
      <Alerts
        id='m_mdl_upload_alert'
        msg={showAlert.msg}
        open={showAlert.visible}
        type={showAlert.type}
        success={showAlert.success}
        close={() => toggleModalInfo()}
      />
    </>
  )
}

ModalUpload.defaultProps = {
  showModal: false
}

export default ModalUpload
