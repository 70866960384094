import styled from 'styled-components'
import { FontSize } from '.'

export const WrapperContent = styled.div`
  margin: 10px 30px;
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
`
export const FlexChildWrapper = styled.div`
  flex: ${(props) => props.flexGrow || 0};
`

export const TitleMenu = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 600;
  padding: 0px 0px 5px 0px;
  text-transform: uppercase;
  font-size: ${FontSize.mediumLarge}px;
`
export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5px;
  position: relative;
`
export const ActionCell = styled.div`
  cursor: pointer;
`
