import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'

import {
  DialogCon,
  TitleDelete,
  QuestionText,
  TitleWrapper,
  DialogWrapper,
  ButtonWrapper,
  ButtonTitleWrapper
} from './modal-delete.styled.component'

import Button from '../button'

import { Colors } from '../../styles'
import { Box } from '@material-ui/core'

const ModalDel = ({ type, deleteText, toggleDialog, submitDelete }) => {
  return (
    <DialogWrapper style={{ borderRadius: 5 }}>
      <DialogCon>
        <TitleDelete>
          <TitleWrapper>
            {' '}
            {type === 'unallocated' ? 'Remove!' : 'Delete!'}{' '}
          </TitleWrapper>
          <div>
            {type === 'unallocated' ? (
              <KeyboardReturnIcon
                style={{ fontSize: '80px', color: Colors.deleteColor }}
              />
            ) : (
              <DeleteIcon
                style={{ fontSize: '60px', color: Colors.deleteColor }}
              />
            )}
          </div>
        </TitleDelete>
      </DialogCon>
      <ButtonTitleWrapper>
        <QuestionText>{deleteText}</QuestionText>
        <Box display='flex'>
          <ButtonWrapper>
            <Button
              color='grey'
              id='del_exit'
              onClick={toggleDialog}
              type='confirmation'
            >
              Cancel
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              color='red'
              id='m_del_submit'
              onClick={submitDelete}
              type='confirmation'
            >
              {type === 'unallocated' ? 'Remove' : 'Delete'}
            </Button>
          </ButtonWrapper>
        </Box>
      </ButtonTitleWrapper>
    </DialogWrapper>
  )
}
export default ModalDel
