import React from 'react'
import Button from '../button'

import {
  DialogCon,
  TitleDelete,
  QuestionText,
  TitleWrapper,
  ButtonWrapper,
  DialogWrapper,
  ButtonTitleWrapper,
} from './modal.confirmation.syled'

const ModalConfirmation = (props) => {
  const { open, close, title, message, icon, onClickYes, isConfirm, isWarn, isActivity } = props
  return (
    <DialogWrapper open={open} onClose={close} maxWidth='sm' disableBackdropClick>
      <DialogCon>
        <TitleDelete>
          <TitleWrapper>{title}</TitleWrapper>
          <div>{icon}</div>
        </TitleDelete>
      </DialogCon>
      <ButtonTitleWrapper>
        <QuestionText>{message}</QuestionText>
        <div>
          {isConfirm && (
            <div>
              <ButtonWrapper>
                <Button
                  color='grey'
                  id='modal_confirmation_no_btn'
                  type='confirmation'
                  onClick={() => {
                    close()
                  }}
                >
                  No
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  color={(isConfirm && isActivity) ? 'yellow' : 'blue'}
                  id='modal_confirmation_yes_btn'
                  type='confirmation'
                  onClick={() => {
                    onClickYes()
                  }}
                >
                  Yes
                </Button>
              </ButtonWrapper>
            </div>
          )}
          {isWarn && (
            <ButtonWrapper>
              <Button
                color='yellow'
                id='modal_confirmation_ok_btn'
                type='confirmation'
                onClick={() => {
                  close()
                }}
              >
                OK
              </Button>
            </ButtonWrapper>
          )}
        </div>
      </ButtonTitleWrapper>
    </DialogWrapper>
  )
}

export default ModalConfirmation
