import _ from 'lodash'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Search } from '@material-ui/icons'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'

import { Loading } from '../../components'
import DashboardAPI from '../../api/dashboard/dashboard-maps/index'
import {
  SearchBarContainer,
  SearchTextContainer,
  SearchButton,
  LoadingContainer,
  StyledSelect,
} from './maps.styled'
import { setToBase64 } from '../../utils'

const Input = withStyles((theme) => ({
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: '1.33vh',
    padding: '1.02vh 1.35vw 1.02vh 0.625vw',
    length: '100%',
    borderRadius: '1.02vh 0 0 1.02vh',
  },
}))(InputBase)

const SearchBar = (props) => {
  const { id, map, setAlert, setVesselDetail, setShowVesselDetail, setMarkerVesselSearch } = props
  const [vessel, setVessel] = useState([])
  const [searchbarValueText, setSearchbarValueText] = useState('')
  const [vesselSearch, setVesselSearch] = useState({ Code: '', Vessel_Name: '' })
  const [inputValue, setInputValue] = useState('')
  const [selectValue, setSelectValue] = useState('vessel_name')
  const [isLoading, setIsLoading] = useState(false)

  const _getVessel = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await DashboardAPI.GetAllMasterDataVessel(0, 100, filter)
      setVessel(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getVessel()
  }, [])

  useEffect(() => {
    setShowVesselDetail(false)
    if (vesselSearch && vesselSearch.Code) {
      searchVesselByCode(vesselSearch.Code)
    }
  }, [vesselSearch])

  const searchVesselByCode = async (code) => {
    try {
      setIsLoading(true)
      setMarkerVesselSearch(null)
      let { data } = await DashboardAPI.GetVesselDetailByCode(code)
      data['Image_Filebase64'] = await setToBase64(data.Image_Filename)
      if (data && (data.Imo_No || data.Mmsi || data.Device_ID)) {
        if (data.Latitude && data.Longitude) {
          setIsLoading(false)
          const targetLocation = {
            lat: parseFloat(data.Latitude),
            lng: parseFloat(data.Longitude),
            color: data.Color_Indicator,
          }
          setMarkerVesselSearch(targetLocation)
          map.panTo(targetLocation)
          map.setZoom(15)
          setVesselDetail(data)
          setShowVesselDetail(true)
        } else {
          setIsLoading(false)
          setAlert({ msg: 'Vessel Location Not Found', success: false, visible: true })
        }
      } else {
        setIsLoading(false)
        setAlert({ msg: 'Vessel Data Not Found', success: false, visible: true })
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const _handleClick = async () => {
    setShowVesselDetail(false)
    if (selectValue !== 'vessel_name') {
      if (searchbarValueText) {
        try {
          setIsLoading(true)
          setMarkerVesselSearch(null)
          const { data, response: errResponse } = await DashboardAPI.GetVesselDetailByString(
            selectValue,
            searchbarValueText
          )
          if (data && (data.Imo_No || data.Device_ID || data.Mmsi)) {
            if (data.Latitude && data.Longitude) {
              setIsLoading(false)
              const targetLocation = {
                lat: parseFloat(data.Latitude),
                lng: parseFloat(data.Longitude),
                color: data.Color_Indicator,
              }
              setMarkerVesselSearch(targetLocation)
              map.panTo(targetLocation)
              map.setZoom(15)
              setVesselDetail(data)
              setShowVesselDetail(true)
            } else {
              setIsLoading(false)
              setAlert({ msg: 'Vessel Location Not Found', success: false, visible: true })
            }
          } else {
            setIsLoading(false)
            if (
              errResponse &&
              errResponse.data &&
              errResponse.data.error.includes('more than 2 days')
            ) {
              setAlert({ msg: errResponse.data.error, success: false, visible: true })
            } else {
              setAlert({ msg: 'Vessel Data Not Found', success: false, visible: true })
            }
          }
        } catch (error) {
          setIsLoading(false)
          console.log(error)
        }
      }
    } else {
      if (vesselSearch && vesselSearch.Code) {
        searchVesselByCode(vesselSearch.Code)
      }
    }
  }

  const _handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      _handleClick()
    }
  }

  const MyPopper = function (props) {
    return (
      <Popper
        {...props}
        placement='bottom-start'
        container={() => document.querySelector('.fullscreen')}
      />
    )
  }

  return (
    <SearchBarContainer>
      <StyledSelect
        value={selectValue}
        onChange={(e) => {
          if (e.target.value === 'vessel_name') {
            setVesselSearch({ Code: '', Vessel_Name: '' })
          }
          setSelectValue(e.target.value)
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          container: () => document.querySelector('.fullscreen'),
        }}
        input={<Input />}
      >
        <MenuItem dense value='vessel_name'>
          Vessel Name
        </MenuItem>
        <MenuItem dense value='mmsi'>
          Mmsi
        </MenuItem>
        <MenuItem dense value='device_id'>
          {' '}
          Device Id
        </MenuItem>
        <MenuItem dense value='imo_no'>
          Imo no
        </MenuItem>
      </StyledSelect>
      <SearchTextContainer>
        {selectValue === 'vessel_name' ? (
          <Autocomplete
            fullWidth
            autoHighlight
            id={`${id}-txt`}
            options={_.orderBy(vessel, ['Vessel_Name'], ['asc'])}
            getOptionLabel={(option) => option['Vessel_Name']}
            getOptionSelected={(option, v) => (v['Code'] ? option['Code'] === v['Code'] : true)}
            value={vesselSearch}
            onChange={(e, v) => setVesselSearch(v)}
            inputValue={inputValue}
            onInputChange={(e, i) => {
              setInputValue(i)
              setSearchbarValueText(i)
            }}
            renderInput={(params) => (
              <InputBase
                fullWidth
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder='Search'
              />
            )}
            PopperComponent={MyPopper}
          />
        ) : (
          <InputBase
            fullWidth
            id={`${id}-txt`}
            placeholder='Search'
            value={searchbarValueText}
            onKeyUp={_handleKeyUp}
            onChange={(e) => setSearchbarValueText(e.target.value)}
            autoComplete='off'
          />
        )}
      </SearchTextContainer>
      {isLoading ? (
        <LoadingContainer>
          <Loading id='dashboard-searchbar-loading-indicator' />
        </LoadingContainer>
      ) : (
        <SearchButton id={`${id}-btn`} onClick={_handleClick}>
          <Search
            style={{
              color: '#95c123',
              fontSize: '1.4em',
            }}
          />
        </SearchButton>
      )}
    </SearchBarContainer>
  )
}

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SearchBar
