import { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'

import { SET_WINDOW_SIZE } from '../../redux/actions'

const HOC = ({ children }) => {
  const dispatch = useDispatch()

  const _resize = () => {
    dispatch({
      type: SET_WINDOW_SIZE,
      payload: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  useEffect(() => {
    _resize()
    window.addEventListener('resize', () => {
      _resize()
    })
  }, [])

  return children
}

HOC.propTypes = {
  children: PropTypes.any.isRequired,
}

export default HOC
