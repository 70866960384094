import { Tooltip, Box } from '@material-ui/core'

import { Button, Search } from '../../components'
import { Delete as DeleteIcon } from '../../components/svg'
import { Images } from '../../assets'
import { Colors } from '../../styles'
import { WrapperButton, IconButton } from './button-grid.styled'

const ButtonGrid = (props) => {
  const styles = {
    marginAddNew: { marginLeft: 20 },
    marginUploadIcon: { width: 10, marginLeft: 20 }
  }

  return (
    <WrapperButton>
      {props.enableDelete && (
        <Button
          onClick={props.onDelete}
          borderColor={Colors.softBlue}
          style={styles.marginUploadIcon}
          color={Colors.transparent}
          disable={props.inEdit}
        >
          <DeleteIcon color={Colors.softBlue} />
          <Box color={Colors.softBlue}> Delete </Box>
        </Button>
      )}
      {props.enableSearch && (
        <Search
          reset={props.reset}
          search={props.search}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          onClickReset={props.onClickReset}
          onSearchClick={props.onSearchClick}
          onChangeSearch={props.onChangeSearch}
        />
      )}
      {props.enableUpload && (
        <Tooltip title='Import/Export' arrow>
          <Button
            width={4.2}
            onClick={props.onUpload}
            borderColor={Colors.softBlue}
            style={styles.marginUploadIcon}
            color={Colors.transparent}
          >
            <IconButton right={0} src={Images.cargoIcon} />
          </Button>
        </Tooltip>
      )}
      <Button
        style={styles.marginAddNew}
        onClick={props.onAdd}
        disable={props.inEdit}
        color={props.inEdit ? 'newgrey' : 'newblue'}
      >
        <IconButton src={Images.AddIcon} />
        Add New
      </Button>
    </WrapperButton>
  )
}

ButtonGrid.defaultProps = {
  enableUpload: false,
  enableDelete: false,
  inEdit: false,
  onUpload: () => {},
  onAdd: () => {}
}

export default ButtonGrid
