import { Colors } from '../../styles'
import { ButtonCancelSaveContainer, ButtonCancel, ButtonSave } from './modal-unfinalized.styled'

const CancelSaveButtons = (props) => {
  return (
    <ButtonCancelSaveContainer>
      <ButtonCancel
        id={props.cancelId}
        color={Colors.modalGrey}
        onClick={props.onCancel}
        backgroundColor={Colors.white}
      >
        {props.cancelText}
      </ButtonCancel>
      <ButtonSave
        id={props.saveId}
        color={Colors.white}
        onClick={props.onSave}
        disable={props.disableSave}
        backgroundColor={Colors.softBlue}
      >
        {props.saveText}
      </ButtonSave>
    </ButtonCancelSaveContainer>
  )
}

export default CancelSaveButtons