import PropTypes from 'prop-types'

import SC from './inline-editable-grid.styled'
import FC from './inline-editable-grid.factory-components'

const InlineEditableGrid = ({
  forceEdit,
  data,
  sticky,
  columns,
  rowData,
  subModule,
  location,
  disabled,
  tableWidth,
  handleEdit,
  handleSave,
  formSubmit,
  tableHeight,
  isFixedCell,
  handleRemove,
  handleCancel,
  hideActionColumn
}) => {
  const filteredColumn = columns.filter(
    (column) => column.field.toLocaleLowerCase() !== 'action'
  )

  return (
    <SC.Container tableHeight={tableHeight}>
      <FC.TableHeader
        sticky={sticky}
        tableWidth={tableWidth}
        isFixedCell={isFixedCell}
        noAction={hideActionColumn ? true : false}
        columns={hideActionColumn ? filteredColumn : columns}
      />
      <FC.TableBody
        forceEdit={forceEdit}
        data={data}
        sticky={sticky}
        rowData={rowData}
        disabled={disabled}
        location={location}
        subModule={subModule}
        tableWidth={tableWidth}
        formSubmit={formSubmit}
        handleEdit={handleEdit}
        handleSave={handleSave}
        tableheight={tableHeight}
        isFixedCell={isFixedCell}
        handleRemove={handleRemove}
        handleCancel={handleCancel}
        noAction={hideActionColumn ? true : false}
        columns={hideActionColumn ? filteredColumn : columns}
      />
    </SC.Container>
  )
}

InlineEditableGrid.propTypes = {
  forceEdit: PropTypes.bool,
  subModule: PropTypes.string,
  isFixedCell: PropTypes.bool,
  hideActionColumn: PropTypes.bool,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  formSubmit: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

InlineEditableGrid.defaultProps = {
  forceEdit: false,
  hideActionColumn: false,
  subModule: ''
}

export default InlineEditableGrid

/*
  Docs:
  For example usage, you can refer to tab cargo and equipment labour on execution and settlement pages.
  File location: ~/containers/execution/modal/cargo-labour-equipment/cargo/grid.cargo.js
*/
