import {
  FormControlLabel,
  Checkbox as CustomCheckbox,
} from '@material-ui/core'

import { Title, Container, ContainerTitle } from './checbox.styled'

const Checkbox = (props) => {
  return (
    <>
      {props.istitle && (
        <ContainerTitle>
          <Title>{props.title}</Title>
        </ContainerTitle>
      )}
      <Container>
        <FormControlLabel
          disabled={props.readOnly}
          control={
            <CustomCheckbox
              id='sr-mdl-sr-yes-btn'
              checked={props.form.values[props.name] ? true : false}
              onChange={() =>
                props.form.setFieldValue(props.name, true)
              }
              name='state'
              color='primary'
            />
          }
          label='Yes'
        />
        <FormControlLabel
          disabled={props.readOnly}
          control={
            <CustomCheckbox
              id='sr-mdl-sr-no-txt'
              checked={props.form.values[props.name] ? false : true}
              onChange={() =>
                props.form.setFieldValue(props.name, false)
              }
              name='state'
              color='primary'
            />
          }
          label='No'
        />
      </Container>
    </>
  )
}
Checkbox.defaultProps = {
  istitle: true,
  readOnly: false,
}
export default Checkbox