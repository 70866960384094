import React from 'react'

import {
  Reject,
  DialogCon,
  TitleDelete,
  QuestionText,
  TitleWrapper,
  DialogWrapper,
  ButtonWrapper,
  ButtonTitleWrapper,
  Form,
} from './modal-reject.styled.component'
import { Images } from '../../assets'
import Button from '../button'
import Input from '../input'
import LogApi from '../../api/master-data/log'
import { Lookup } from '../../utils'

const ModalReject = ({ rejectText, toggleDialog, ServiceRequestRejectForm, typeModal, typeLog, subtypeLog, srCode, isClicked }) => {
  const insertLog = async ()=>{
    const dataInsert = {
      Timestamp:new Date(),
      Action: Lookup.LOG_ACTION.UPDATE,
      Module:`${typeLog}`,
      Submodule:`${subtypeLog}`,
      Detail:'Remarks Cannot be Empty',
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From:'',
      To:'',
      Refno: `${srCode}`
    }

    if (!ServiceRequestRejectForm.isValid || ServiceRequestRejectForm.values.RejectRemarks === '') {
      const { data } = await LogApi.PostDataLog(dataInsert)
      if (data && data.result)
        return data.result
    }
    else  return false
  }
  return (
    <DialogWrapper style={{ borderRadius: 5 }}>
      <DialogCon>
        <TitleDelete>
          <TitleWrapper>
            {typeModal === 'doConfirmExct'
              ? 'CONFIRM!'
              : typeModal === 'revise'
                ? 'Revise!'
                : 'Reject!'}
          </TitleWrapper>
          <div>
            <Reject
              src={
                typeModal === 'doConfirmExct'
                  ? Images.confirm
                  : typeModal === 'revise'
                    ? Images.reviseLogo
                    : Images.reject
              }
            />
          </div>
        </TitleDelete>
      </DialogCon>
      <ButtonTitleWrapper>
        <QuestionText>{rejectText}</QuestionText>
        <Form>
          <Input
            form={ServiceRequestRejectForm}
            style={{ width: '280px' }}
            id='input-txt-reject'
            label='Remarks'
            name='RejectRemarks'
            multiline={true}
            rowsMax={3}
            variant='outlined'
            value={ServiceRequestRejectForm.values.RejectRemarks}
          />
        </Form>
        <div>
          <ButtonWrapper>
            <Button color='grey' id='reject_exit' onClick={toggleDialog} type='confirmation'>
              No
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              style={{
                backgroundColor:
                  typeModal === 'doConfirmExct'
                    ? '#008FC4'
                    : typeModal === 'revise'
                      ? '#F79E00'
                      : 'red',
              }}
              disable={isClicked ? true : false}
              id='m_reject_submit'
              type='confirmation'
              onClick={() => {
                ServiceRequestRejectForm.handleSubmit()
                insertLog()
              }}
            >
              Yes
            </Button>
          </ButtonWrapper>
        </div>
      </ButtonTitleWrapper>
    </DialogWrapper>
  )
}
export default ModalReject
