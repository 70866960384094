import styled from 'styled-components'

export const Status = styled.div`
    color: white;
    line-height: 1;
    font-size: 14px;
    width: max-content;
    text-align: center;
    text-align: center;
    border-radius: 2px;
    white-space: nowrap;
    margin: 10px 0px 5px;
    padding: 5px 7px 5px 7px;
`
export const Time = styled.div`
    display: flex;
    color: #818181;
    padding-top: 5px;
`
export const DataTime = styled.div`
    display: flex; 
    line-height: 1.5;
    padding-bottom: 5px; 
`
export const Connect = styled.div`
    top: 6%;
    border: 15px solid; 
    position : absolute; 
    transform: translateX(-1.4vw) translateY(2vw);
    border-color:transparent transparent #ffff transparent; 
`
export const NoData = styled.h5`
    font-size: large;
    padding-top : 60%;
    text-align : center;
`