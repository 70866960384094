import { Provider } from 'react-redux'
import { PropTypes } from 'prop-types'
import { PersistGate } from 'redux-persist/integration/react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { store, persistor } from '../../redux/store'
import { reactPlugin } from '../../utils/AppInsight'
const Context = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>{children}</PersistGate>
      </Provider>
    </AppInsightsContext.Provider>
  )
}

Context.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Context
