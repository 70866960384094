import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import styled, { css } from 'styled-components'
import { FontSize, Colors } from '../../styles'

export const CustomModal = styled(Dialog)`
    .MuiDialog-paperWidthMd {
      width: 640px;
      height: 480px;
    }
`
export const DialogWrapper = styled.div`
    width: 100%;
    height: 100%;
    display : flex;
    flex-direction: column;
    justify-content: space-between;
`

export const TitleWrapper = styled.div`
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin: 20px 20px 15px;
`

export const Title = styled.div`
  text-align: left;
`
export const ButtonWrapper = styled.div`
    margin: 21px;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    justify-content: flex-end;
`
export const ContainerConfirmation = styled.div`
    display: flex;
    padding: 55px 90px;
    align-items: center;
    flex-direction: column;
`
export const Paragraph = styled.p`
    text-align:center;
    margin-bottom: 38px;
    color: ${Colors.modalGrey};
    font-size: ${FontSize.mediumLarge}px;
`
export const TitleConfirm = styled.p`
  font-weight: 600;
  margin: 23px 0px 0px;
  font-size: ${FontSize.extraLarge}px;
`
export const Icon = styled.img`
    width:30px;
    height:30px;
`
export const IconClose = styled.img`
    top: 17px;
    right: 24px;
    width: 30px;
    height: auto;
    cursor: pointer;
    position: absolute;
    ${() =>
  css`
        &:hover {
          padding: 3px;
          border-radius: 100px;
          background: #E0EEF5;
        }
      `}
    `
export const Wrapper = styled.div`
    height: 100%;
    padding: 25px;
`
export const WrapperUpload = styled.div`
    height: 212px;
    width: 240px;
    font-size: 14px;
    cursor: pointer;
    padding-top: 40px;
    font-weight: bold;
    text-align: center;
    margin-inline: 17px;
    background: ${(p) => p.backgroundColor ?
  p.backgroundColor : 'transparent'};
    color: ${Colors.modalGrey};
    border: 1px dashed ${Colors.dashGray};
    ${
      css`
        &:hover {
        border-color: ${Colors.softBlue}  
      }
      &:active {
        border-color: ${Colors.lightBlue}  
      }
   `}
`
export const WrapperDocument = styled.div`
    left: 0;
    right: 0;
    top: 100px;
    height: auto;
    display: flex;
    padding: 2px 25px;
    background: #FAFBFD;
    margin-inline: 21px;
    align-items: center;
    position: absolute;
    justify-content: space-between;
`
export const Image = styled.img`
    user-select: none;
    width: 3em;
    height: 3em;
`
export const IconCancel = styled.img`
    right: 20px;
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    ${() =>
  css`
          &:hover {
            padding: 3px;
            border-radius: 100px;
            background: #E0EEF5;
          }
        `}
`
export const WrapperBottom = styled.div`
    top: 150px;
    width: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
`
export const TitleIcon = styled.p`
    color: #333;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 10px;
`
export const TitleDoc = styled.p`
    font-weight: 600;
    margin-inline: 20px;
    font-size: ${(p) => p.size ? p.size : 16}px;
    line-height: ${(p) => p.lineHeight ? p.lineHeight : 17}px;
    color: ${(p) => p.color ? p.color : Colors.cancelTextColor};
`
export const Header = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    padding: 5px 15px;
    background-color: ${Colors.softBlue};
  }
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const DivTitle = styled.div`
  width: auto;
  color: white;
  display: flex;
  min-width: 12.5%;
  align-items: center;
  justify-content: space-between;
`
export const ModalIcon = styled.img`
`