import {
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
  TimelineViews,
  TimelineMonth,
  ViewDirective,
  ViewsDirective,
  ScheduleComponent,
  ResourceDirective,
  ResourcesDirective,
} from '@syncfusion/ej2-react-schedule'
import { loadCldr } from '@syncfusion/ej2-base'

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/en-GB/ca-gregorian.json'),
  require('cldr-data/main/en-GB/numbers.json'),
  require('cldr-data/main/en-GB/timeZoneNames.json')
)

const Scheduler = (props) => {
  const {
    refs,
    data,
    date,
    group,
    height,
    listVessel,
    // handleDrag,
    onPopupOpen,
    onResizeStop,
    // handleResize,
    eventTemplate,
    // handlerRender,
    onEventDragStop,
    tooltipTemplate,
    headerDayTemplate,
    headerWeekTemplate,
    headerMonthTemplate,
    resourceHeaderTemplate,
  } = props

  return (
    <ScheduleComponent
      // created={handlerRender}
      // navigating={handlerRender}
      ref={refs}
      rowAutoHeight={true}
      locale='en-GB'
      height={height}
      currentView='Day'
      selectedDate={date}
      eventSettings={{
        dataSource: data,
        enableTooltip: true,
        tooltipTemplate: tooltipTemplate,
        fields: {
          startTime: { title: 'Max_Arrival', name: 'Max_Arrival' },
          endTime: { title: 'Max_Depart', name: 'Max_Depart' },
        },
      }}
      group={group}
      popupOpen={onPopupOpen}
      dragStop={onEventDragStop}
      resizeStop={onResizeStop}
      firstDayOfWeek={date.getDay()}
      resourceHeaderTemplate={resourceHeaderTemplate}
      // resizeStart={handleResize}
      // dragStart={handleDrag}
      {...props}
    >
      <ResourcesDirective>
        <ResourceDirective
          field='Jetty_Code'
          title='Jetty'
          name='Jetties'
          allowMultiple={false}
          dataSource={listVessel}
          textField='name'
          idField='Jetty_Code'
        ></ResourceDirective>
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective
          option='TimelineDay'
          displayName='Day'
          eventTemplate={eventTemplate}
          timeScale={{
            enable: true,
            interval: 60,
            slotCount: 2,
          }}
          dateHeaderTemplate={headerDayTemplate}
        />
        <ViewDirective
          option='TimelineWeek'
          displayName='Week'
          eventTemplate={eventTemplate}
          timeScale={{
            enable: true,
            interval: 720,
            slotCount: 1,
          }}
          dateHeaderTemplate={headerWeekTemplate}
        />
        <ViewDirective
          option='TimelineDay'
          displayName='Month'
          interval={30}
          timeScale={{ enable: false }}
          eventTemplate={eventTemplate}
          dateHeaderTemplate={headerMonthTemplate}
        />
      </ViewsDirective>
      <Inject services={[TimelineViews, TimelineMonth, Agenda, Resize, DragAndDrop]} />
    </ScheduleComponent>
  )
}

export default Scheduler
