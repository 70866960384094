import Select from '@material-ui/core/Select'
import styled from 'styled-components'
import { Colors } from '../../styles'

const colorText = {
  red: 'red',
  black: 'black',
  orange: 'orange',
  green: 'newGreen',
}

export const TooltipContainer = styled.div`
  opacity: 1;
  width: 7.13vw;
  display: flex;
  padding: 0.8vh 0.7vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  box-shadow: 0px 2px 4px #00000080;
  height: ${(p) => (p.bigger ? '7.17vh' : '5.32vh')};
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 0.2vh solid
    ${(p) =>
  p.colorIndicator
    ? Colors[colorText[p.colorIndicator] ? colorText[p.colorIndicator] : 'black']
    : Colors.black};
`

export const TooltipItem = styled.div`
  font-size: ${(p) => (p.bold ? '1.22vh' : '1.33vh')};
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
`

export const VesselDetailWrapper = styled.div`
  top: 2vh;
  right: 1vw;
  position: absolute;
  color: ${Colors.pureWhite};
`

export const VesselDetailContainer = styled.div`
  width: 18.2vw;
  height: 65.04vh;
  opacity: 0.97;
  margin-top: 2.45vh;
  border-radius: 2vh;
  box-shadow: 0px 3px 6px #00000029;
  background: #0a81ad 0% 0% no-repeat padding-box;
`

export const DetailTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DetailTitle = styled.div`
  height: 6.65vh;
  padding: 1.12vh 1.19vw;
`

export const DetailRow = styled.div`
  height: 3.38vh;
  font-size: 1.43vh;
  padding: 0.9vh 1.14vw;
  color: #ffffff;
  background: transparent linear-gradient(180deg, #6f7275 0%, #888888 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DetailRowFlexItem = styled.div`
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  text-align: right;
  max-width: 11.45vw;
`

export const Bullets = styled.span`
  &:before {
    content: 'o';
  }
  color: #95c123;
  margin-right: 0.88vw;
`

export const DetailInformation = styled.div`
  height: 3.58vh;
  font-size: 1.43vh;
  font-weight: bold;
  padding: 0.5vh 1.2vw;
`

export const SearchBarContainer = styled.div`
  width: 18.2vw;
  height: 4.1vh;
  opacity: 0.9;
  display: flex;
  font-size: 1.43vh;
  padding-right: 1.2vw;
  border-radius: 1vh;
  align-items: center;
  color: ${Colors.black};
  box-shadow: 0 3px 6px #00000029;
  background: #f2f2f2 0% 0% no-repeat padding-box;
`

export const SearchTextContainer = styled.div`
  padding: 0 1.53vh;
  width: 100%;
`

export const SearchButton = styled.div`
  padding-top: 0.5vh;
  cursor: pointer;
`

export const LoadingContainer = styled.div`
  padding: o.5vh;
`
export const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    padding-right: 2.46vh;
  }
  & .MuiSelect-icon {
    top: calc(50% - 1vh);
  }
`
