import React from 'react'
import moment from 'moment'
import Link from 'next/link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { DashboardAPI } from '../../api'
import { Title } from '../../styles/global.styled'
import {
  Time,
  NoData,
  Status,
  Connect,
  DataTime
} from './list-notification.styled.component'

const useStyles = makeStyles({
  container: {
    width: '20%',
    height: '1000%',
    cursor: 'default',
    position: 'absolute',
    transform: 'translateX(-16.5vw) translateY(3.2vw)'
  },
  header: {
    height: 'min-content',
    fontSize: '30px',
    lineHeight: '1.5',
    padding: '10px 0px 10px 20px',
    borderBottom: '1px solid #00000029'
  },
  footer: {
    height: 'max-content',
    alignContent: 'center',
    padding: '10px 0px 10px 0px',
    borderTop: '1px solid #00000029'
  },
  footerFont: {
    fontSize: '18px',
    color: '#0A81AD',
    margin: '1.2% 40%',
    cursor: 'pointer',
    fontWeight: 'bold',
    textAlign: 'center',
    width: 'max-content',
    textDecoration: 'underline'
  },
  listItem: {
    // height: '28vw',
    height: '82%',
    padding: '0px',
    overflowY: 'auto'
  },
  description: {
    fontSize: '16px',
    color: '#000000',
    lineHeight: '1.3',
    whiteSpace: 'initial',
    textDecoration: 'none'
  },
  timeIcon: {
    width: '4.5%',
    height: '4.5%',
    color: '#818181',
    margin: '2px 5px 2px 0px'
  },
  item: {
    display: 'revert',
    background: '#E2F7FF',
    borderBottom: '1px solid #00000029'
  },
  itemRead: {
    display: 'revert',
    background: '#FFFFFF',
    borderBottom: '1px solid #00000029'
  },
  statusNeedApprove: {
    backgroundColor: '#FF9B00'
  },
  statusNeedConfirm: {
    backgroundColor: '#009DB0'
  },
  statusPlanArrival: {
    backgroundColor: '#84328B'
  },
  statusNeedRevise: {
    backgroundColor: '#6DB200'
  }
})

const ListNotification = (props) => {
  const classes = useStyles()
  const { dataNotif: dp } = props

  return (
    <div>
      <Connect />
      <ClickAwayListener onClickAway={props.handleClose}>
        <Paper className={classes.container}>
          <div className={classes.header}>
            <Title style={{ paddingTop: '10px' }}>Notification</Title>
          </div>
          {!dp ? (
            <div className={classes.listItem}>
              <NoData>No records available</NoData>
            </div>
          ) : (
            <div className={classes.listItem}>
              {dp.slice(0, 10).map((dataNotif, index) => {
                return (
                  <Link key={index} href={`/notification?id=${dataNotif.ID}`}>
                    {
                      <div
                        style={{ cursor: 'pointer', padding: '5px 20px' }}
                        id={`list-notif-text-${dataNotif.ID}`}
                        onClick={() => {
                          const dataTemp = {
                            ID: dataNotif.ID,
                            Is_Read: true
                          }
                          try {
                            DashboardAPI.UpdateIsRead(dataTemp)
                          } catch (error) {
                            console.log(error)
                          }
                        }}
                        className={
                          dataNotif.Is_Read ? classes.itemRead : classes.item
                        }
                      >
                        <div id={`list-notif-item-${index}`}>
                          <Status
                            className={
                              dataNotif.Notification_Type_Text.split('|')[0] ===
                              'NeedsApproval'
                                ? classes.statusNeedApprove
                                : dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === 'NeedConfirmation'
                                ? classes.statusNeedConfirm
                                : dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === '2hoursbeforeETA'
                                ? classes.statusPlanArrival
                                : dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === 'NeedToBeRevised' ||
                                  dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === 'SettlementRevised' ||
                                  dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === 'Unfinalized'
                                ? classes.statusNeedRevise
                                : dataNotif.Notification_Type_Text.split(
                                    '|'
                                  )[0] === 'Pre Finalized'
                                ? classes.statusNeedConfirm
                                : null
                            }
                          >
                            {dataNotif.Notification_Type_Text.split('|')[0] ===
                            'NeedsApproval'
                              ? 'Needs Approval [Service Request]'
                              : dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === 'NeedConfirmation'
                              ? 'Need Confirmation'
                              : dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === '2hoursbeforeETA'
                              ? 'Planning Arrival'
                              : dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === 'NeedToBeRevised' ||
                                dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === 'SettlementRevised' ||
                                dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === 'Unfinalized'
                              ? 'Need Revise'
                              : dataNotif.Notification_Type_Text.split(
                                  '|'
                                )[0] === 'Pre Finalized'
                              ? 'Need Finalized'
                              : null}
                          </Status>
                          {dataNotif.Notification_Type_Text.split('|')[0] ===
                          'NeedsApproval' ? (
                            <div>
                              <Typography className={classes.description}>
                                {dataNotif.User_Name_Sender} create new service
                                request number {dataNotif.Code_Ref} and need for
                                approval
                              </Typography>
                              <Time>
                                <AccessTimeIcon className={classes.timeIcon} />
                                <DataTime>
                                  {moment(dataNotif.Created_Date).format(
                                    'DD MMM YYYY'
                                  )}
                                  &nbsp; at &nbsp;
                                  {moment(dataNotif.Created_Date).format(
                                    'HH:mm'
                                  )}
                                </DataTime>
                              </Time>
                            </div>
                          ) : dataNotif.Notification_Type_Text.split('|')[0] ===
                            'NeedConfirmation' ? (
                            <div>
                              <Typography className={classes.description}>
                                {dataNotif.Code_Ref} has been completed and need
                                your approval
                              </Typography>
                              <Time className={classes.needApprovalTime}>
                                <AccessTimeIcon className={classes.timeIcon} />
                                <DataTime>
                                  {moment(dataNotif.Created_Date).format(
                                    'DD MMM YYYY'
                                  )}
                                  &nbsp; at &nbsp;
                                  {moment(dataNotif.Created_Date).format(
                                    'HH:mm'
                                  )}
                                </DataTime>
                              </Time>
                            </div>
                          ) : dataNotif.Notification_Type_Text.split('|')[0] ===
                            '2hoursbeforeETA' ? (
                            <div>
                              <Typography className={classes.description}>
                                Please prepare vessel berthing before 2 hours
                                Plan Arrival with Service Request No{' '}
                                {dataNotif.Code_Ref}
                              </Typography>
                              <Time>
                                <AccessTimeIcon className={classes.timeIcon} />
                                <DataTime>
                                  {moment(dataNotif.Created_Date).format(
                                    'DD MMM YYYY'
                                  )}
                                  &nbsp; at &nbsp;
                                  {moment(dataNotif.Created_Date).format(
                                    'HH:mm'
                                  )}
                                </DataTime>
                              </Time>
                            </div>
                          ) : dataNotif.Notification_Type_Text.split('|')[0] ===
                              'NeedToBeRevised' ||
                            dataNotif.Notification_Type_Text.split('|')[0] ===
                              'SettlementRevised' ||
                            dataNotif.Notification_Type_Text.split('|')[0] ===
                              'Unfinalized' ? (
                            <div>
                              <Typography className={classes.description}>
                                {' '}
                                {dataNotif.Code_Ref} needs your action to revise
                              </Typography>
                              <Time>
                                <AccessTimeIcon className={classes.timeIcon} />
                                <DataTime>
                                  {moment(dataNotif.Created_Date).format(
                                    'DD MMM YYYY'
                                  )}
                                  &nbsp; at &nbsp;
                                  {moment(dataNotif.Created_Date).format(
                                    'HH:mm'
                                  )}
                                </DataTime>
                              </Time>
                            </div>
                          ) : dataNotif.Notification_Type_Text.split('|')[0] ===
                            'Pre Finalized' ? (
                            <div>
                              <Typography className={classes.description}>
                                {dataNotif.Code_Ref} need your action to
                                finalize
                              </Typography>
                              <Time className={classes.needApprovalTime}>
                                <AccessTimeIcon className={classes.timeIcon} />
                                <DataTime>
                                  {moment(dataNotif.Created_Date).format(
                                    'DD MMM YYYY'
                                  )}
                                  &nbsp; at &nbsp;
                                  {moment(dataNotif.Created_Date).format(
                                    'HH:mm'
                                  )}
                                </DataTime>
                              </Time>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    }
                  </Link>
                )
              })}
            </div>
          )}
          <div className={classes.footer}>
            <Link href={'/notification'}>
              <Typography id='view-notif-btn' className={classes.footerFont}>
                View All
              </Typography>
            </Link>
          </div>
        </Paper>
      </ClickAwayListener>
    </div>
  )
}
export default ListNotification
