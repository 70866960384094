export const DraftGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 39.001 39.001' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#fff}.prefix__b{fill:#adadad}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M38.764 6.738l-6.5-6.5A.818.818 0 0031.688 0H2.438A2.44 2.44 0 000 2.438v34.125A2.44 2.44 0 002.438 39h34.125A2.44 2.44 0 0039 36.563V7.313a.818.818 0 00-.236-.575zm0 0'
      />
      <path
        className='prefix__a'
        d='M9.75 0v10.563A2.441 2.441 0 0012.188 13h14.625a2.441 2.441 0 002.438-2.438V0zm0 0M30.063 14.625H8.938a4.067 4.067 0 00-4.063 4.062V39h29.251V18.687a4.067 4.067 0 00-4.063-4.062zm0 0'
      />
      <path
        className='prefix__a'
        d='M25.188 3.25h-3.25a.813.813 0 00-.813.812v4.875a.813.813 0 00.813.813h3.25A.813.813 0 0026 8.937V4.062a.813.813 0 00-.812-.812zm0 0'
      />
      <path
        className='prefix__b'
        d='M36.563 39H2.438A2.44 2.44 0 010 36.563V2.438A2.44 2.44 0 012.438 0h29.25a.818.818 0 01.575.237l6.5 6.5a.818.818 0 01.237.576v29.25A2.44 2.44 0 0136.563 39zM2.438 1.625a.815.815 0 00-.813.813v34.125a.815.815 0 00.813.813h34.125a.815.815 0 00.813-.813V7.649l-6.024-6.024zm0 0'
      />
      <path
        className='prefix__b'
        d='M26.813 13H12.188a2.441 2.441 0 01-2.438-2.437V.813A.813.813 0 0110.563 0h17.875a.813.813 0 01.813.813v9.75A2.441 2.441 0 0126.813 13zM11.375 1.625v8.938a.815.815 0 00.813.812h14.625a.815.815 0 00.813-.812V1.625zm0 0M33.313 39.001H5.688a.813.813 0 01-.813-.813v-19.5a4.066 4.066 0 014.063-4.063h21.125a4.066 4.066 0 014.063 4.062v19.5a.813.813 0 01-.813.814zM6.5 37.376h26V18.687a2.441 2.441 0 00-2.438-2.438H8.938A2.441 2.441 0 006.5 18.687zm0 0'
      />
      <path
        className='prefix__b'
        d='M25.188 9.75h-3.25a.813.813 0 01-.813-.813V4.062a.813.813 0 01.813-.812h3.25a.813.813 0 01.812.812v4.875a.813.813 0 01-.812.813zM22.75 8.125h1.625v-3.25H22.75zm0 0M28.438 22.75H10.563a.813.813 0 110-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 27.626H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 32.501H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0'
      />
    </svg>
  )
}

export const WaitAppGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 44.881 45.082' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#adadad}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M44.855 7.518c0-.013 0-.026-.006-.039a2.708 2.708 0 00-.024-.124l-.018-.072a2.2 2.2 0 00-.088-.272l-.021-.051-.018-.039a1.522 1.522 0 00-.039-.082l-.011-.021-.052-.1-.007-.012q-.03-.051-.063-.1l-.007-.006q-.034-.051-.07-.1l-.006-.008-.075-.1-.008-.01q-.038-.045-.078-.089l-.012-.014q-.038-.041-.079-.081l-.017-.017a2.949 2.949 0 00-.082-.076l-.018-.017A3.05 3.05 0 0043.951 6l-3.382-2.668L37.188.659c-.051-.037-.095-.072-.143-.106l-.03-.021a2.952 2.952 0 00-.322-.189l-.01-.005c-.069-.038-.14-.064-.211-.094l-.043-.017q-.1-.038-.2-.07l-.02-.007a3.282 3.282 0 00-.488-.111h-.012A3.4 3.4 0 0035.22 0H17.179a3.107 3.107 0 00-3.314 2.836v18.807h-.171c-7.551 0-13.693 5.258-13.693 11.72s6.143 11.72 13.693 11.72c6.106 0 11.291-3.439 13.052-8.173h14.822a3.107 3.107 0 003.314-2.836V7.828a2.081 2.081 0 00-.027-.31zm-8.287-4.952l5.405 4.274h-4.9a.476.476 0 01-.508-.434zM25.137 35.148a8.785 8.785 0 01-.576 1.761c-1.674 3.745-5.916 6.412-10.872 6.412-6.416 0-11.636-4.468-11.636-9.959S7.277 23.4 13.693 23.4h.171a13.528 13.528 0 012.057.182c5.353.893 9.407 4.936 9.407 9.775a8.584 8.584 0 01-.191 1.791zm17.682-1.075a1.178 1.178 0 01-1.256 1.075H27.228a10.105 10.105 0 00.159-1.786c0-5.813-4.97-10.65-11.464-11.564V2.836a1.178 1.178 0 011.256-1.075H34.51v4.645a2.4 2.4 0 002.565 2.2h5.748z'
      />
      <path
        className='prefix__a'
        d='M15.922 25.232a11.417 11.417 0 00-2.057-.234h-.264c-5.442 0-9.868 3.789-9.868 8.447a7.542 7.542 0 001.4 4.345 1.131 1.131 0 001.412.3.813.813 0 00.352-1.208 5.967 5.967 0 01-1.11-3.438c0-3.686 3.5-6.685 7.811-6.685.088 0 .176 0 .264.006a8.976 8.976 0 012.057.3c3.177.848 5.49 3.389 5.49 6.384a5.769 5.769 0 01-.259 1.707 6.184 6.184 0 01-.876 1.761 8.161 8.161 0 01-6.675 3.217 9.226 9.226 0 01-1.075-.063 1.013 1.013 0 00-1.159.752.915.915 0 00.879.992 11.628 11.628 0 001.356.079 10.076 10.076 0 009-4.978 7.556 7.556 0 00.668-1.761 7.278 7.278 0 00.2-1.707c.001-3.981-3.221-7.321-7.546-8.216zM21.702 10.985a.891.891 0 100 1.761h15.353a.891.891 0 100-1.761zM33.967 17.468a.963.963 0 00-1.029-.88H21.702a.891.891 0 100 1.761h11.237a.963.963 0 001.028-.881zM37.051 22.192h-9.916a.891.891 0 100 1.761h9.916a.891.891 0 100-1.761zM37.054 27.795h-7.166a.891.891 0 100 1.761h7.166a.891.891 0 100-1.761z'
      />
      <path
        className='prefix__a'
        d='M13.601 34.321h3.456a.891.891 0 100-1.761h-2.863l-.329-.485-2.065-3.048a1.128 1.128 0 00-1.406-.32.815.815 0 00-.374 1.2l2.691 3.972a1.071 1.071 0 00.89.442zM9.598 39.373a.843.843 0 00-.095-.152.885.885 0 00-.128-.134.97.97 0 00-.156-.109 1.148 1.148 0 00-.37-.131 1.185 1.185 0 00-.4 0 1.139 1.139 0 00-.192.05 1.116 1.116 0 00-.177.081.97.97 0 00-.156.109.887.887 0 00-.129.134.838.838 0 00-.095.152.8.8 0 00-.059.165.746.746 0 00-.02.172.764.764 0 00.02.173.833.833 0 00.059.165.859.859 0 00.095.151.886.886 0 00.129.134.97.97 0 00.156.109 1.119 1.119 0 00.177.081 1.139 1.139 0 00.192.05 1.2 1.2 0 00.2.018 1.178 1.178 0 00.2-.018 1.148 1.148 0 00.37-.131.97.97 0 00.156-.109.884.884 0 00.128-.134.865.865 0 00.095-.151.841.841 0 00.059-.165.76.76 0 00.02-.173.747.747 0 00-.02-.172.8.8 0 00-.059-.165zM38.003 17.086a.829.829 0 00-.095-.152.879.879 0 00-.127-.134.975.975 0 00-.157-.109 1.126 1.126 0 00-.178-.081 1.182 1.182 0 00-.787 0 1.168 1.168 0 00-.178.081 1.008 1.008 0 00-.155.109.893.893 0 00-.129.134.837.837 0 00-.095.152.792.792 0 00-.059.165.741.741 0 00-.02.172.754.754 0 00.02.173.83.83 0 00.059.165.861.861 0 00.095.151.89.89 0 00.129.134 1.079 1.079 0 00.155.11 1.147 1.147 0 00.37.131 1.2 1.2 0 00.2.017 1.165 1.165 0 00.393-.067 1.121 1.121 0 00.178-.081 1.041 1.041 0 00.157-.11.877.877 0 00.127-.134.853.853 0 00.095-.151.834.834 0 00.059-.165.759.759 0 00.021-.173.746.746 0 00-.021-.172.789.789 0 00-.057-.165z'
      />
    </svg>
  )
}

export const AppRejGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 37.639 41.061' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#adadad}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M37.639 3.422A3.426 3.426 0 0034.222 0H6.844a3.426 3.426 0 00-3.422 3.422v23.952a.684.684 0 001.369 0V3.422a2.053 2.053 0 012.053-2.053h27.378a2.053 2.053 0 012.053 2.053v26.69h-4.79a3.426 3.426 0 00-3.422 3.422v4.79h-13a.684.684 0 000 1.369h13.68a.684.684 0 00.484-.2l8.212-8.212a.684.684 0 00.2-.484zM31.48 31.48h3.823l-5.876 5.876v-3.823a2.053 2.053 0 012.053-2.053z'
      />
      <path
        className='prefix__a'
        d='M0 32.849a2.718 2.718 0 00.8 1.94l5.477 5.47a2.722 2.722 0 001.935.8 1.843 1.843 0 00.22-.009 2.733 2.733 0 002-1.129l12.32-17.107a2.738 2.738 0 10-4.443-3.2l-.864 1.2a.685.685 0 101.111.8l.864-1.2a1.369 1.369 0 112.221 1.6L9.323 39.123a1.363 1.363 0 01-1 .565 1.38 1.38 0 01-1.079-.4L1.77 33.817a1.369 1.369 0 010-1.935 1.4 1.4 0 011.935 0l3.768 3.767a.675.675 0 00.539.2.684.684 0 00.5-.282l8.075-11.213a.685.685 0 10-1.111-.8L7.87 34.112l-3.2-3.2a2.806 2.806 0 00-3.871 0A2.718 2.718 0 000 32.849zM9.581 4.106H8.212a.684.684 0 000 1.369h1.369a.684.684 0 100-1.369zM32.849 4.106H12.318a.684.684 0 100 1.369h20.53a.684.684 0 100-1.369zM9.581 8.212H8.212a.684.684 0 100 1.369h1.369a.684.684 0 100-1.369zM12.318 9.581h15.74a.684.684 0 100-1.369h-15.74a.684.684 0 100 1.369zM9.581 12.318H8.212a.684.684 0 000 1.369h1.369a.684.684 0 000-1.369zM12.318 13.687h10.95a.684.684 0 000-1.369h-10.95a.684.684 0 100 1.369zM34.902 14.371a.684.684 0 00-1.369 0v9.581a.684.684 0 001.369 0zM34.217 26.005a.684.684 0 00-.684.684v1.369a.684.684 0 001.369 0v-1.369a.684.684 0 00-.685-.684z'
      />
    </svg>
  )
}

export const ProgGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 43.792 44.766' {...props}>
      <defs>
        <style>{'.prefix__a{fill:rgba(52,52,52,.4)}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M11.191 13.138h21.41v1.946h-21.41zM30.655 16.057h1.946v1.946h-1.946zM11.191 16.057h1.946v1.946h-1.946zM20.923 6.812h1.946v1.946h-1.946zM23.843 6.812h1.946v1.946h-1.946zM18.003 6.812h1.946v1.946h-1.946zM15.084 6.812h1.946v1.946h-1.946zM26.762 6.812h1.946v1.946h-1.946z'
      />
      <path
        className='prefix__a'
        d='M39.685 41.961a5.786 5.786 0 01-3.138.858l5.736-17.209a.973.973 0 00-.524-1.2l-5.265-2.369v-9.39a2.923 2.923 0 00-2.919-2.919h-1.46V4.866a.973.973 0 00-.973-.973h-8.273V0h-1.946v3.893h-8.272a.973.973 0 00-.973.973v4.866h-1.46a2.923 2.923 0 00-2.919 2.919v9.394l-5.266 2.37a.973.973 0 00-.524 1.2l5.736 17.208a5.786 5.786 0 01-3.138-.858A7.68 7.68 0 000 40.877v1.946a5.791 5.791 0 013.191.859 7.678 7.678 0 004.107 1.088 8.07 8.07 0 001.427-.11 7.889 7.889 0 002.68-.98 5.781 5.781 0 013.182-.856 5.791 5.791 0 013.191.859 7.678 7.678 0 004.107 1.088 7.68 7.68 0 004.107-1.088 6.37 6.37 0 016.384 0 7.928 7.928 0 002.689.979 8.057 8.057 0 001.427.11 7.68 7.68 0 004.107-1.088 5.791 5.791 0 013.191-.859v-1.952a7.677 7.677 0 00-4.105 1.088zm.473-16.132l-.7 2.093L22.869 20.3v-2.25zM13.624 5.839h16.544v3.893H13.624zm-4.379 6.812a.974.974 0 01.973-.973h23.356a.974.974 0 01.973.973v8.519l-12.252-5.514a.972.972 0 00-.8 0l-12.25 5.513v-8.518zM3.633 25.829l17.289-7.78V20.3L4.333 27.922zm30.953 16.718a7.9 7.9 0 01-1.295-.59 8.309 8.309 0 00-8.213 0 5.792 5.792 0 01-3.191.859 5.79 5.79 0 01-3.191-.859 7.679 7.679 0 00-4.107-1.088 7.668 7.668 0 00-4.1 1.085 7.872 7.872 0 01-1.284.587L4.951 29.78l15.972-7.339v11.62h1.946v-11.62l15.972 7.339z'
      />
    </svg>
  )
}

export const CompGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 41.001 41.001' {...props}>
      <defs>
        <style>{'.prefix__a{fill:rgba(52,52,52,.4)}'}</style>
      </defs>
      <path className='prefix__a' d='M12.493 29.628a.8.8 0 10.8.8.8.8 0 00-.8-.8z' />
      <path
        className='prefix__a'
        d='M36.047 15.215l4.719-4.717a.8.8 0 00-.33-1.331L28.945 5.618a8.009 8.009 0 00-15.072-.6L.562 9.165a.8.8 0 00-.328 1.333l4.843 4.843-3.054 5.09a.8.8 0 00.433 1.167l4.43 1.477v12.323a.8.8 0 00.52.75c2.709 1.016 12.774 4.791 12.828 4.81a.8.8 0 00.512.007h.007l14.415-4.8a.8.8 0 00.548-.76V22.749l4.725-1.485a.8.8 0 00.326-1.33zm-6.738-7.207c0-.2-.009-.406-.024-.609l9.409 2.9-4 4-6.524-2.176a8.031 8.031 0 001.139-4.115zM15.241 5.924a6.406 6.406 0 0112.466 2.084 6.406 6.406 0 01-6.407 6.407 6.412 6.412 0 01-6.06-8.491zM2.3 10.298l11.078-3.451a8.074 8.074 0 00-.085 1.161 7.96 7.96 0 00.888 3.665L6.3 14.298zm1.61 10.1l2.532-4.22L19.3 20.464l-2.532 4.22zM19.7 39.045l-11.211-4.2V23.613c8.821 2.925 8.39 2.835 8.636 2.835a.8.8 0 00.687-.389l1.888-3.148zm.8-19.869L8.618 15.215l6.47-2.157a8.006 8.006 0 0012.066.414l5.228 1.742zm13.614 15.642L21.3 39.089V22.298l2.839 3.548a.8.8 0 00.865.264l9.109-2.862zm-9.066-10.4l-3.205-4.006 12.857-4.28 4 4z'
      />
      <path
        className='prefix__a'
        d='M17.578 31.407l-1.781-.668a.8.8 0 00-.562 1.5l1.781.668a.8.8 0 10.562-1.5zM17.578 34.828l-4.8-1.8a.8.8 0 10-.562 1.5l4.8 1.8a.8.8 0 00.562-1.5zM25.872 5.039a.8.8 0 00-1.133 0L20.5 9.278l-1.035-1.035a.801.801 0 10-1.133 1.133l1.6 1.6a.8.8 0 001.133 0l4.8-4.8a.8.8 0 00.007-1.137z'
      />
    </svg>
  )
}

export const FinalGrey = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 43.996 41.001' {...props}>
      <defs>
        <style>{'.prefix__a{fill:rgba(52,52,52,.4)}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M41.317 17.666H39.1a2.185 2.185 0 00-2.086-1.6h-2.369v-5.794a.611.611 0 00-.3-.54L17.641.083a.617.617 0 00-.62 0L.31 9.732a.622.622 0 00-.31.54v19.294a.623.623 0 00.312.54l16.713 9.649a.623.623 0 00.312.084.656.656 0 00.321-.084l8.794-5.073v3.667a2.64 2.64 0 002.662 2.647h12.2A2.655 2.655 0 0044 38.354v-18a2.69 2.69 0 00-2.683-2.688zm-4.282-.356a.9.9 0 01.9.9v.06a.9.9 0 01-.9.9h-3.623a.9.9 0 01-.9-.9v-.06a.9.9 0 01.9-.9zm-19.7-15.967l6.944 4.009-15.463 8.929-6.944-4.009zm-.593 36.8l-15.5-8.929V11.351l15.5 8.929zm.593-18.94l-7.273-4.2 15.465-8.928 7.274 4.2zm9.115 1.154v12.89l-8.46 4.893V20.28l15.41-8.929v4.715h.019a2.185 2.185 0 00-2.086 1.6h-2.22a2.675 2.675 0 00-2.662 2.689zm16.3 18a1.407 1.407 0 01-1.432 1.4h-12.2a1.392 1.392 0 01-1.415-1.4v-18a1.427 1.427 0 011.415-1.441h2.243a2.132 2.132 0 002.061 1.514h3.6a2.132 2.132 0 002.061-1.514h2.243a1.442 1.442 0 011.432 1.441z'
      />
      <path
        className='prefix__a'
        d='M29.831 26.662h.653a.624.624 0 100-1.247h-.025v-1.692h1.72a.6.6 0 00.593.421.625.625 0 00.627-.622v-.42a.632.632 0 00-.63-.626h-2.937a.622.622 0 00-.619.626v2.937a.619.619 0 00.619.623zM29.835 32.362h2.939a.623.623 0 00.623-.623V28.8a.623.623 0 00-.623-.623h-2.939a.623.623 0 00-.623.623v2.939a.623.623 0 00.623.623zm.623-2.939h1.692v1.692h-1.692zM30.484 36.815h-.025v-1.692h1.686a.627.627 0 101.253 0v-.6a.65.65 0 00-.63-.647h-2.937a.64.64 0 00-.619.647v2.937a.6.6 0 00.619.6h.653a.624.624 0 100-1.247z'
      />
      <path
        className='prefix__a'
        d='M34.203 23.242l-1.826 1.826-.555-.556a.624.624 0 00-.882.882l1 1a.623.623 0 00.882 0l2.267-2.267a.624.624 0 00-.882-.882zM34.203 34.753l-1.826 1.826-.555-.555a.624.624 0 10-.882.882l1 1a.623.623 0 00.882 0l2.267-2.267a.624.624 0 00-.882-.882zM40.599 23.99h-3.916a.624.624 0 000 1.247h3.916a.624.624 0 100-1.247zM40.599 29.69h-3.916a.624.624 0 000 1.247h3.916a.624.624 0 100-1.247zM40.599 35.39h-3.916a.624.624 0 000 1.247h3.916a.624.624 0 100-1.247z'
      />
    </svg>
  )
}

//---------------------------GREEN VERSION----------------------------------------//

export const DraftGreen = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 39.001 39.001' {...props}>
      <defs>
        <style>{'.prefix__b{fill:#fff}'}</style>
      </defs>
      <path
        d='M38.764 6.738l-6.5-6.5A.818.818 0 0031.688 0H2.438A2.44 2.44 0 000 2.438v34.125A2.44 2.44 0 002.438 39h34.125A2.44 2.44 0 0039 36.563V7.313a.818.818 0 00-.236-.575zm0 0'
        fill='#95c123'
      />
      <path
        className='prefix__b'
        d='M9.75 0v10.563A2.441 2.441 0 0012.188 13h14.625a2.441 2.441 0 002.438-2.438V0zm0 0M30.063 14.625H8.938a4.067 4.067 0 00-4.063 4.062V39h29.251V18.687a4.067 4.067 0 00-4.063-4.062zm0 0'
      />
      <path
        d='M25.188 3.25h-3.25a.813.813 0 00-.813.812v4.875a.813.813 0 00.813.813h3.25A.813.813 0 0026 8.937V4.062a.813.813 0 00-.812-.812zm0 0'
        fill='#455a64'
      />
      <path d='M36.563 39H2.438A2.44 2.44 0 010 36.563V2.438A2.44 2.44 0 012.438 0h29.25a.818.818 0 01.575.237l6.5 6.5a.818.818 0 01.237.576v29.25A2.44 2.44 0 0136.563 39zM2.438 1.625a.815.815 0 00-.813.813v34.125a.815.815 0 00.813.813h34.125a.815.815 0 00.813-.813V7.649l-6.024-6.024zm0 0' />
      <path d='M26.813 13H12.188a2.441 2.441 0 01-2.438-2.437V.813A.813.813 0 0110.563 0h17.875a.813.813 0 01.813.813v9.75A2.441 2.441 0 0126.813 13zM11.375 1.625v8.938a.815.815 0 00.813.812h14.625a.815.815 0 00.813-.812V1.625zm0 0M33.313 39.001H5.688a.813.813 0 01-.813-.813v-19.5a4.066 4.066 0 014.063-4.063h21.125a4.066 4.066 0 014.063 4.062v19.5a.813.813 0 01-.813.814zM6.5 37.376h26V18.687a2.441 2.441 0 00-2.438-2.438H8.938A2.441 2.441 0 006.5 18.687zm0 0' />
      <path d='M25.188 9.75h-3.25a.813.813 0 01-.813-.813V4.062a.813.813 0 01.813-.812h3.25a.813.813 0 01.812.812v4.875a.813.813 0 01-.812.813zM22.75 8.125h1.625v-3.25H22.75zm0 0M28.438 22.75H10.563a.813.813 0 110-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 27.626H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 32.501H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0' />
    </svg>
  )
}

export const WaitAppGreen = (props) => {
  return (
    <div>
      <svg width='1em' height='1em' viewBox='0 0 44.881 45.082' {...props}>
        <defs>
          <style>{'.prefix__a{fill:#95c123}.prefix__b{fill:#fff}'}</style>
        </defs>
        <path
          className='prefix__a'
          d='M43.674 7.151zM35.837.972l.084.026zM43.74 7.289q.025.061.045.123a1.395 1.395 0 00-.045-.123zM43.252 6.654l-4.508-3.565 4.508 3.565zM43.592 7.017c.021.03.039.061.057.092a1.27 1.27 0 00-.057-.092zM37.075 7.721a1.439 1.439 0 01-1.536-1.315v-5.5a2.175 2.175 0 00-.319-.024H17.178a2.14 2.14 0 00-2.285 1.956v19.733c6.431.518 11.464 5.151 11.464 10.791a9.384 9.384 0 01-.387 2.667h15.6a2.14 2.14 0 002.285-1.956V7.829a1.374 1.374 0 00-.026-.254 1.372 1.372 0 01.02.147h-6.77zM36.326 1.192l.042.027zM35.688.937l.092.023zM43.791 7.431a1.392 1.392 0 01.034.136 1.59 1.59 0 00-.034-.136zM36.047 1.047l.056.026zM36.187 1.113l.053.029zM43.38 6.767l.05.052-.05-.052zM35.538.905c.034.005.067.012.1.018-.033-.006-.066-.013-.1-.018zM43.494 6.889c.02.024.039.049.057.074-.02-.025-.037-.05-.057-.074z'
        />
        <path
          className='prefix__a'
          d='M14.894 22.571c-.4-.032-.8-.049-1.2-.049-6.994 0-12.664 4.853-12.664 10.84s5.67 10.84 12.664 10.84c5.919 0 10.89-3.476 12.278-8.173a9.387 9.387 0 00.387-2.667c-.001-5.64-5.034-10.274-11.465-10.791zm-1.293 18.436c-4.882 0-8.84-3.387-8.84-7.566s3.958-7.566 8.84-7.566 8.84 3.387 8.84 7.566-3.958 7.566-8.84 7.566z'
        />
        <ellipse
          className='prefix__b'
          cx={8.84}
          cy={7.566}
          rx={8.84}
          ry={7.566}
          transform='translate(4.761 25.874)'
        />
        <path
          className='prefix__b'
          d='M43.791 7.427l-.006-.018q-.02-.062-.045-.123l-.013-.03a1.35 1.35 0 00-.053-.108l-.024-.042c-.018-.031-.037-.062-.057-.092s-.027-.036-.04-.054-.037-.05-.057-.074-.043-.047-.064-.07-.032-.036-.05-.052q-.061-.059-.128-.113l-4.508-3.565-2.254-1.782a1.482 1.482 0 00-.121-.087l-.042-.027q-.042-.027-.086-.051l-.053-.029-.084-.04-.056-.026a2.348 2.348 0 00-.126-.048L35.84.97l-.056-.016-.092-.023-.049-.01a1.749 1.749 0 00-.1-.018v5.5a1.439 1.439 0 001.536 1.315h6.771a1.38 1.38 0 00-.02-.147v-.008c-.015-.043-.026-.088-.039-.136z'
        />
        <path d='M44.825 7.355l-.018-.072a2.2 2.2 0 00-.088-.272l-.021-.051-.018-.039a1.522 1.522 0 00-.039-.082l-.011-.021-.052-.1-.007-.012q-.03-.051-.063-.1l-.007-.006q-.034-.051-.07-.1l-.006-.008-.075-.1-.008-.01q-.038-.045-.078-.089l-.012-.014q-.038-.041-.079-.081l-.017-.017a2.949 2.949 0 00-.082-.076l-.018-.017A3.05 3.05 0 0043.951 6l-3.383-2.668-3.38-2.673c-.051-.037-.095-.072-.144-.106l-.03-.021a2.951 2.951 0 00-.322-.189l-.01-.005c-.068-.038-.139-.064-.211-.094l-.043-.017q-.1-.038-.2-.07l-.02-.007a3.282 3.282 0 00-.488-.111h-.012A3.4 3.4 0 0035.22 0H17.178a3.107 3.107 0 00-3.314 2.836v18.807h-.171C6.143 21.643 0 26.901 0 33.363s6.143 11.72 13.693 11.72c6.106 0 11.291-3.439 13.052-8.173h14.822a3.107 3.107 0 003.314-2.836V7.828a2.083 2.083 0 00-.026-.31c0-.013 0-.026-.006-.039-.007-.042-.012-.079-.024-.124zm-2.852-.515h-4.9a.476.476 0 01-.508-.434v-3.84zM25.137 35.148a8.786 8.786 0 01-.576 1.761c-1.674 3.745-5.916 6.412-10.872 6.412-6.416 0-11.636-4.468-11.636-9.959S7.277 23.4 13.693 23.4h.171a13.527 13.527 0 012.057.182c5.353.893 9.407 4.936 9.407 9.775a8.585 8.585 0 01-.191 1.791zm17.682-1.075a1.178 1.178 0 01-1.256 1.075H27.228a10.105 10.105 0 00.159-1.786c0-5.813-4.97-10.65-11.464-11.564V2.836a1.178 1.178 0 011.256-1.075H34.51v4.645a2.4 2.4 0 002.565 2.2h5.748v25.466z' />
        <path d='M15.922 25.232a11.414 11.414 0 00-2.057-.234h-.264c-5.441 0-9.868 3.789-9.868 8.446a7.543 7.543 0 001.4 4.345 1.131 1.131 0 001.412.3.813.813 0 00.352-1.208 5.967 5.967 0 01-1.11-3.438c0-3.686 3.5-6.685 7.811-6.685.088 0 .176 0 .264.006a8.977 8.977 0 012.057.3c3.177.848 5.49 3.389 5.49 6.384a5.77 5.77 0 01-.259 1.707 6.183 6.183 0 01-.876 1.761 8.161 8.161 0 01-6.675 3.217 9.219 9.219 0 01-1.075-.063 1.013 1.013 0 00-1.159.752.915.915 0 00.879.992 11.627 11.627 0 001.356.079 10.076 10.076 0 009-4.978 7.557 7.557 0 00.668-1.761 7.279 7.279 0 00.2-1.707c.001-3.98-3.221-7.32-7.546-8.215zM21.702 10.985a.891.891 0 100 1.761h15.353a.891.891 0 100-1.761zM33.967 17.469a.963.963 0 00-1.029-.881H21.702a.891.891 0 100 1.761h11.237a.963.963 0 001.028-.88zM37.051 22.192h-9.916a.891.891 0 100 1.761h9.916a.891.891 0 100-1.761zM37.053 27.795h-7.166a.891.891 0 100 1.761h7.166a.891.891 0 100-1.761z' />
        <path d='M13.601 34.321h3.456a.891.891 0 100-1.761h-2.863l-.329-.485-2.065-3.048a1.129 1.129 0 00-1.406-.32.815.815 0 00-.374 1.2l2.691 3.972a1.071 1.071 0 00.89.442zM9.599 39.373a.843.843 0 00-.095-.152.887.887 0 00-.128-.134.971.971 0 00-.156-.109 1.148 1.148 0 00-.37-.131 1.187 1.187 0 00-.4 0 1.136 1.136 0 00-.192.05 1.115 1.115 0 00-.177.081.972.972 0 00-.156.109.889.889 0 00-.129.134.835.835 0 00-.095.152.8.8 0 00-.059.165.747.747 0 00-.02.172.765.765 0 00.02.173.834.834 0 00.059.165.858.858 0 00.095.151.889.889 0 00.129.134.975.975 0 00.156.109 1.115 1.115 0 00.177.081 1.136 1.136 0 00.192.05 1.2 1.2 0 00.2.018 1.174 1.174 0 00.2-.018 1.148 1.148 0 00.37-.131.975.975 0 00.156-.109.887.887 0 00.128-.134.867.867 0 00.095-.151.84.84 0 00.059-.165.761.761 0 00.02-.173.748.748 0 00-.02-.172.8.8 0 00-.059-.165zM38.003 17.086a.836.836 0 00-.095-.152.881.881 0 00-.127-.134.974.974 0 00-.156-.109 1.127 1.127 0 00-.178-.081 1.175 1.175 0 00-.192-.05 1.187 1.187 0 00-.4 0 1.147 1.147 0 00-.37.131 1.01 1.01 0 00-.155.109.888.888 0 00-.129.134.841.841 0 00-.095.152.793.793 0 00-.059.165.743.743 0 00-.02.172.759.759 0 00.02.173.83.83 0 00.059.165.866.866 0 00.095.151.89.89 0 00.129.134 1.08 1.08 0 00.155.11 1.146 1.146 0 00.37.131 1.2 1.2 0 00.2.017 1.165 1.165 0 00.393-.067 1.125 1.125 0 00.178-.081 1.037 1.037 0 00.156-.11.882.882 0 00.127-.134.861.861 0 00.095-.151.837.837 0 00.059-.165.761.761 0 00.021-.173.748.748 0 00-.021-.172.794.794 0 00-.06-.165z' />
      </svg>
    </div>
  )
}

export const AppRejGreen = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 36.881 40.234' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#95c123}.prefix__b{fill:#fff}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M4.024 3.353A2.682 2.682 0 016.706.671h26.818a2.682 2.682 0 012.682 2.682v26.818l-8.042 8.051H6.706a2.682 2.682 0 01-2.682-2.682z'
      />
      <path
        className='prefix__b'
        d='M33.528.671h-2.012a2.682 2.682 0 012.682 2.682v26.818l-8.046 8.051h2.012l8.046-8.051V3.353A2.682 2.682 0 0033.528.671zM17.636 20.788l.846-1.175a2.012 2.012 0 013.266 2.351L9.678 38.727a2.012 2.012 0 01-1.471.829 1.271 1.271 0 01-.16.007 2.012 2.012 0 01-1.422-.587L1.26 33.609a2.012 2.012 0 112.845-2.845l3.692 3.692 7.91-10.986'
      />
      <path
        className='prefix__a'
        d='M2.093 30.766l3.692 3.691.842-1.169-2.522-2.522a2.006 2.006 0 00-2.428-.312 2 2 0 01.416.311zM21.292 19.155a2.012 2.012 0 00-2.188-.1c.058.034.12.062.176.1a2.012 2.012 0 01.457 2.808L7.668 38.727a2.006 2.006 0 01-.622.56 2 2 0 001 .276 1.319 1.319 0 00.162-.007 2.012 2.012 0 001.471-.829l12.07-16.764a2.012 2.012 0 00-.457-2.808z'
      />
      <path className='prefix__b' d='M28.164 38.223v-5.365a2.682 2.682 0 012.682-2.682h5.364' />
      <path d='M36.881 3.353A3.357 3.357 0 0033.528 0H6.706a3.357 3.357 0 00-3.353 3.353v23.47a.671.671 0 001.341 0V3.353a2.012 2.012 0 012.012-2.012h26.822a2.012 2.012 0 012.012 2.012V29.5h-4.694a3.357 3.357 0 00-3.353 3.353v4.694h-12.74a.671.671 0 100 1.341h13.411a.671.671 0 00.474-.2l8.047-8.047a.671.671 0 00.2-.474zm-6.035 27.493h3.746L28.834 36.6v-3.743a2.012 2.012 0 012.012-2.011z' />
      <path d='M0 32.187a2.663 2.663 0 00.786 1.9l5.364 5.361a2.667 2.667 0 001.9.786 1.8 1.8 0 00.216-.009 2.678 2.678 0 001.957-1.108l12.07-16.764a2.682 2.682 0 10-4.353-3.136l-.846 1.175a.671.671 0 101.088.783L19.028 20a1.341 1.341 0 112.177 1.566L9.135 38.335a1.335 1.335 0 01-.979.553A1.352 1.352 0 017.1 38.5l-5.366-5.365a1.341 1.341 0 010-1.9 1.373 1.373 0 011.9 0l3.692 3.691a.662.662 0 00.528.194.671.671 0 00.49-.276l7.913-10.987a.671.671 0 10-1.088-.783L7.711 33.425l-3.132-3.134a2.749 2.749 0 00-3.793 0A2.663 2.663 0 000 32.187zM9.388 4.023H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM32.187 4.023H12.071a.671.671 0 100 1.341h20.116a.671.671 0 100-1.341zM9.388 8.047H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM12.071 9.388h15.422a.671.671 0 100-1.341H12.071a.671.671 0 100 1.341zM9.388 12.07H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM12.071 13.411H22.8a.671.671 0 100-1.341H12.071a.671.671 0 100 1.341zM34.199 14.082a.671.671 0 10-1.341 0v9.388a.671.671 0 101.341 0zM33.529 25.481a.671.671 0 00-.671.671v1.341a.671.671 0 001.341 0v-1.341a.671.671 0 00-.67-.671z' />
    </svg>
  )
}

export const ProgGreen = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 44.06 45.04' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#95c123}.prefix__b{fill:#fff}.prefix__c{fill:#231f20}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M40.144 29.374l.147.049-4.847 14.541c-2.468-.382-2.889-1.861-6.08-1.861-3.672 0-3.672 1.958-7.343 1.958s-3.672-1.958-7.343-1.958c-3.182 0-3.6 1.478-6.061 1.861L3.771 29.423l.147-.049 18.113-8.327z'
      />
      <path
        className='prefix__b'
        d='M41.613 25.457l-1.321 3.965-.147-.049-18.115-8.322v-4.406l13.6 6.12z'
      />
      <path
        className='prefix__a'
        d='M12.728 10.77h21.051a1.964 1.964 0 011.958 1.958v9.791l-.108.245-13.6-6.12-13.6 6.12-.108-.245v-9.791a1.964 1.964 0 011.958-1.958z'
      />
      <path
        className='prefix__b'
        d='M22.031 16.645v4.406L3.917 29.374l-.147.049-1.322-3.965 5.982-2.693z'
      />
      <path className='prefix__a' d='M31.331 10.771h-18.6V4.896h18.6z' />
      <path
        className='prefix__c'
        d='M11.26 13.218h21.541v1.958H11.26zM30.842 16.156H32.8v1.958h-1.958zM11.26 16.156h1.958v1.958H11.26zM21.051 6.854h1.958v1.958h-1.958zM23.988 6.854h1.958v1.958h-1.958zM18.114 6.854h1.958v1.958h-1.958zM15.176 6.854h1.958v1.958h-1.958zM26.926 6.854h1.958v1.958h-1.958z'
      />
      <path
        className='prefix__c'
        d='M39.933 42.218a5.821 5.821 0 01-3.157.863l5.771-17.314a.979.979 0 00-.527-1.2l-5.3-2.384v-9.454a2.941 2.941 0 00-2.937-2.937h-1.472V4.9a.979.979 0 00-.979-.979h-8.323V0h-1.958v3.917h-8.318a.979.979 0 00-.979.979v4.9h-1.473a2.941 2.941 0 00-2.937 2.937v9.447l-5.3 2.384a.979.979 0 00-.527 1.2l5.771 17.314a5.821 5.821 0 01-3.155-.86 7.726 7.726 0 00-4.132-1.094v1.958a5.826 5.826 0 013.211.864 7.726 7.726 0 004.131 1.094 8.119 8.119 0 001.436-.111 7.937 7.937 0 002.7-.986 5.817 5.817 0 013.2-.862 5.826 5.826 0 013.211.864 7.727 7.727 0 004.132 1.094 7.727 7.727 0 004.132-1.094 6.408 6.408 0 016.423 0 7.977 7.977 0 002.705.985 8.106 8.106 0 001.435.111 7.727 7.727 0 004.132-1.094 5.827 5.827 0 013.211-.864v-1.96a7.726 7.726 0 00-4.127 1.095zm-.226-14.124l-16.693-7.67v-2.265l17.395 7.828zM13.708 5.875h16.645v3.916H13.708zm-3.427 5.875h23.5a.98.98 0 01.979.979V21.3l-12.327-5.548a.978.978 0 00-.8 0L9.301 21.3v-8.57a.98.98 0 01.98-.981zm10.77 6.41v2.264L4.358 28.093l-.7-2.106zm8.313 22.964a7.727 7.727 0 00-4.131 1.094 5.827 5.827 0 01-3.211.864 5.826 5.826 0 01-3.211-.864 7.726 7.726 0 00-4.132-1.094 7.715 7.715 0 00-4.123 1.092 7.92 7.92 0 01-1.292.591L4.981 29.962l16.07-7.383v11.69h1.958v-11.69l16.07 7.384-4.281 12.844a7.944 7.944 0 01-1.3-.593 7.747 7.747 0 00-4.134-1.091z'
      />
    </svg>
  )
}

export const CompGreen = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40.999 40.998' {...props}>
      <defs>
        <style>{'.prefix__b{fill:#95c123}.prefix__c{fill:#fff}'}</style>
      </defs>
      <path d='M26.9 12.544l-11.521-.428-9.293 3.098 14.414 4.8 14.414-4.8z' fill='#e2e2e2' />
      <path
        className='prefix__b'
        d='M28.507 8.008A7.207 7.207 0 1121.3.799a7.207 7.207 0 017.207 7.209zM34.907 22.162l-14.408-2.145-12.811 2.484v12.893l12.812 4.8 14.414-4.8V22.181z'
      />
      <path className='prefix__c' d='M28.292 6.255L26.9 12.543l8.012 2.671 5.285-5.285z' />
      <path
        className='prefix__c'
        d='M20.5 20.019l4.265 5.331 15.434-4.85-5.286-5.288zM2.709 20.841l14.414 4.8 3.376-5.627-14.414-4.802z'
      />
      <path className='prefix__c' d='M14.482 5.663L.801 9.929l5.285 5.285 9.293-3.1z' />
      <path d='M12.492 31.226a.8.8 0 10-.8-.8.8.8 0 00.8.8z' />
      <path d='M5.077 15.338l-3.054 5.091a.8.8 0 00.433 1.169l4.43 1.477v12.323a.8.8 0 00.52.75c2.709 1.016 12.773 4.79 12.827 4.809a.8.8 0 00.512.007h.007l14.414-4.8a.8.8 0 00.548-.76V22.748l4.724-1.485a.8.8 0 00.326-1.33l-4.719-4.719 4.719-4.716a.8.8 0 00-.33-1.331L28.943 5.618a8.009 8.009 0 00-15.071-.6L.562 9.165a.8.8 0 00-.328 1.333zm1.364.839L19.3 20.463l-2.532 4.22-12.859-4.285zm8.646-3.119a8.005 8.005 0 0012.065.414l5.228 1.742-11.88 3.961-11.882-3.961zm-6.6 10.553c8.82 2.925 8.39 2.835 8.635 2.835a.8.8 0 00.687-.389L19.7 22.91v16.132L8.487 34.838V23.611zm25.624 11.2L21.3 39.087V22.298l2.839 3.548a.8.8 0 00.865.264l9.108-2.862zm4.589-14.68l-13.651 4.29-3.2-4.006L34.7 16.13zm-4-5.833l-6.528-2.176a8.03 8.03 0 001.135-4.114c0-.2-.009-.406-.024-.609l9.408 2.9zm-13.4-12.7a6.4 6.4 0 016.406 6.41 6.405 6.405 0 01-6.406 6.406 6.408 6.408 0 110-12.816zm-7.923 5.248a8.074 8.074 0 00-.085 1.161 7.959 7.959 0 00.888 3.665L6.3 14.298l-4-4z' />
      <path d='M15.233 32.237l1.781.668a.8.8 0 10.562-1.5l-1.781-.668a.8.8 0 10-.562 1.5zM17.577 34.826l-4.8-1.8a.8.8 0 10-.562 1.5l4.8 1.8a.8.8 0 00.562-1.5zM19.933 10.976a.8.8 0 001.132 0l4.8-4.8a.801.801 0 00-1.133-1.133l-4.233 4.235-1.035-1.035a.801.801 0 00-1.133 1.133z' />
    </svg>
  )
}

export const FinalGreen = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 45.01 41.81' {...props}>
      <defs>
        <style>{'.prefix__a,.prefix__b{fill:#95c123}.prefix__a{stroke:#000}'}</style>
      </defs>
      <g transform='translate(-22.5 -40.14)'>
        <path d='M52.224 62.232h14v18h-14z' />
        <path
          className='prefix__a'
          d='M40.063 59.653l16.4-9.468-7.713-4.453-16.4 9.468zM39.433 60.798L23 51.329v18.939l16.433 9.469z'
        />
        <path className='prefix__b' d='M53.978 70.494h1.794v1.794h-1.794z' />
        <path
          className='prefix__a'
          d='M40.755 60.798v18.939l8.972-5.188V60.877a2.837 2.837 0 012.823-2.851h2.353a2.317 2.317 0 012.212-1.7h-.02v-5z'
        />
        <rect
          className='prefix__a'
          width={5.761}
          height={1.983}
          rx={0.992}
          transform='translate(56.15 57.649)'
        />
        <path
          className='prefix__a'
          d='M47.426 44.969l-7.364-4.252-16.4 9.468 7.364 4.252zM65.491 59.349h-2.378a2.261 2.261 0 01-2.186 1.606h-3.813a2.261 2.261 0 01-2.186-1.606H52.55a1.513 1.513 0 00-1.5 1.528v19.087a1.476 1.476 0 001.5 1.484h12.941a1.491 1.491 0 001.519-1.484V60.877a1.529 1.529 0 00-1.518-1.528zM52.65 63.791a.66.66 0 01.656-.664h3.114a.67.67 0 01.668.664v.445a.663.663 0 01-.665.66.637.637 0 01-.629-.446h-1.823v1.794h.026a.661.661 0 110 1.322h-.692a.656.656 0 01-.656-.66zm0 6.041a.661.661 0 01.661-.661h3.117a.661.661 0 01.661.661v3.117a.661.661 0 01-.661.661h-3.117a.661.661 0 01-.661-.661zm1.348 9.822h-.692a.638.638 0 01-.656-.638v-3.114a.679.679 0 01.656-.687h3.114a.69.69 0 01.668.687v.637a.665.665 0 11-1.329 0h-1.788v1.795h.026a.661.661 0 110 1.322zm4.879-2.575l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.941-1.928a.661.661 0 01.935.935zm0-12.207l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.936-1.936a.661.661 0 01.935.935zm5.848 13.27h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322z'
        />
        <path
          className='prefix__b'
          d='M65.492 59.349h-2.378a2.261 2.261 0 01-2.186 1.606h-3.813a2.261 2.261 0 01-2.186-1.606h-2.378a1.513 1.513 0 00-1.5 1.528v19.087a1.476 1.476 0 001.5 1.484h12.941a1.491 1.491 0 001.519-1.484V60.877a1.529 1.529 0 00-1.518-1.528zm-12.841 4.442a.66.66 0 01.656-.664h3.114a.67.67 0 01.668.664v.445a.663.663 0 01-.665.66.637.637 0 01-.629-.446h-1.823v1.794h.026a.661.661 0 110 1.322h-.692a.656.656 0 01-.656-.66zm0 6.041a.661.661 0 01.661-.661h3.117a.661.661 0 01.661.661v3.117a.661.661 0 01-.661.661h-3.117a.661.661 0 01-.661-.661zm1.348 9.822h-.692a.638.638 0 01-.656-.638v-3.114a.679.679 0 01.656-.687h3.114a.69.69 0 01.668.687v.637a.665.665 0 11-1.329 0h-1.788v1.795h.026a.661.661 0 110 1.322zm4.879-2.575l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.941-1.928a.661.661 0 01.935.935zm0-12.207l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.936-1.936a.661.661 0 01.935.935zm5.848 13.27h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322z'
        />
      </g>
    </svg>
  )
}

//-----------------------RED-------------------//

export const DraftRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 39.001 39.001' {...props}>
      <defs>
        <style>{'.prefix__b{fill:#fff}'}</style>
      </defs>
      <path
        d='M38.764 6.738l-6.5-6.5A.818.818 0 0031.688 0H2.438A2.44 2.44 0 000 2.438v34.125A2.44 2.44 0 002.438 39h34.125A2.44 2.44 0 0039 36.563V7.313a.818.818 0 00-.236-.575zm0 0'
        fill='red'
      />
      <path
        className='prefix__b'
        d='M9.75 0v10.563A2.441 2.441 0 0012.188 13h14.625a2.441 2.441 0 002.438-2.438V0zm0 0M30.063 14.625H8.938a4.067 4.067 0 00-4.063 4.062V39h29.251V18.687a4.067 4.067 0 00-4.063-4.062zm0 0'
      />
      <path
        d='M25.188 3.25h-3.25a.813.813 0 00-.813.812v4.875a.813.813 0 00.813.813h3.25A.813.813 0 0026 8.937V4.062a.813.813 0 00-.812-.812zm0 0'
        fill='#455a64'
      />
      <path d='M36.563 39H2.438A2.44 2.44 0 010 36.563V2.438A2.44 2.44 0 012.438 0h29.25a.818.818 0 01.575.237l6.5 6.5a.818.818 0 01.237.576v29.25A2.44 2.44 0 0136.563 39zM2.438 1.625a.815.815 0 00-.813.813v34.125a.815.815 0 00.813.813h34.125a.815.815 0 00.813-.813V7.649l-6.024-6.024zm0 0' />
      <path d='M26.813 13H12.188a2.441 2.441 0 01-2.438-2.437V.813A.813.813 0 0110.563 0h17.875a.813.813 0 01.813.813v9.75A2.441 2.441 0 0126.813 13zM11.375 1.625v8.938a.815.815 0 00.813.812h14.625a.815.815 0 00.813-.812V1.625zm0 0M33.313 39.001H5.688a.813.813 0 01-.813-.813v-19.5a4.066 4.066 0 014.063-4.063h21.125a4.066 4.066 0 014.063 4.062v19.5a.813.813 0 01-.813.814zM6.5 37.376h26V18.687a2.441 2.441 0 00-2.438-2.438H8.938A2.441 2.441 0 006.5 18.687zm0 0' />
      <path d='M25.188 9.75h-3.25a.813.813 0 01-.813-.813V4.062a.813.813 0 01.813-.812h3.25a.813.813 0 01.812.812v4.875a.813.813 0 01-.812.813zM22.75 8.125h1.625v-3.25H22.75zm0 0M28.438 22.75H10.563a.813.813 0 110-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 27.626H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0M28.438 32.501H10.563a.813.813 0 010-1.625h17.875a.813.813 0 010 1.625zm0 0' />
    </svg>
  )
}

export const WaitAppRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 44.881 45.082' {...props}>
      <defs>
        <style>{'.prefix__a{fill:red}.prefix__b{fill:#fff}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M43.674 7.151zM35.837.972l.084.026zM43.74 7.289q.025.061.045.123a1.395 1.395 0 00-.045-.123zM43.252 6.654l-4.508-3.565 4.508 3.565zM43.592 7.017c.021.03.039.061.057.092a1.27 1.27 0 00-.057-.092zM37.075 7.721a1.439 1.439 0 01-1.536-1.315v-5.5a2.175 2.175 0 00-.319-.024H17.178a2.14 2.14 0 00-2.285 1.956v19.733c6.431.518 11.464 5.151 11.464 10.791a9.384 9.384 0 01-.387 2.667h15.6a2.14 2.14 0 002.285-1.956V7.829a1.374 1.374 0 00-.026-.254 1.372 1.372 0 01.02.147h-6.77zM36.326 1.192l.042.027zM35.688.937l.092.023zM43.791 7.431a1.392 1.392 0 01.034.136 1.59 1.59 0 00-.034-.136zM36.047 1.047l.056.026zM36.187 1.113l.053.029zM43.38 6.767l.05.052-.05-.052zM35.538.905c.034.005.067.012.1.018-.033-.006-.066-.013-.1-.018zM43.494 6.889c.02.024.039.049.057.074-.02-.025-.037-.05-.057-.074z'
      />
      <path
        className='prefix__a'
        d='M14.894 22.571c-.4-.032-.8-.049-1.2-.049-6.994 0-12.664 4.853-12.664 10.84s5.67 10.84 12.664 10.84c5.919 0 10.89-3.476 12.278-8.173a9.387 9.387 0 00.387-2.667c-.001-5.64-5.034-10.274-11.465-10.791zm-1.293 18.436c-4.882 0-8.84-3.387-8.84-7.566s3.958-7.566 8.84-7.566 8.84 3.387 8.84 7.566-3.958 7.566-8.84 7.566z'
      />
      <ellipse
        className='prefix__b'
        cx={8.84}
        cy={7.566}
        rx={8.84}
        ry={7.566}
        transform='translate(4.761 25.874)'
      />
      <path
        className='prefix__b'
        d='M43.791 7.427l-.006-.018q-.02-.062-.045-.123l-.013-.03a1.35 1.35 0 00-.053-.108l-.024-.042c-.018-.031-.037-.062-.057-.092s-.027-.036-.04-.054-.037-.05-.057-.074-.043-.047-.064-.07-.032-.036-.05-.052q-.061-.059-.128-.113l-4.508-3.565-2.254-1.782a1.482 1.482 0 00-.121-.087l-.042-.027q-.042-.027-.086-.051l-.053-.029-.084-.04-.056-.026a2.348 2.348 0 00-.126-.048L35.84.97l-.056-.016-.092-.023-.049-.01a1.749 1.749 0 00-.1-.018v5.5a1.439 1.439 0 001.536 1.315h6.771a1.38 1.38 0 00-.02-.147v-.008c-.015-.043-.026-.088-.039-.136z'
      />
      <path d='M44.825 7.355l-.018-.072a2.2 2.2 0 00-.088-.272l-.021-.051-.018-.039a1.522 1.522 0 00-.039-.082l-.011-.021-.052-.1-.007-.012q-.03-.051-.063-.1l-.007-.006q-.034-.051-.07-.1l-.006-.008-.075-.1-.008-.01q-.038-.045-.078-.089l-.012-.014q-.038-.041-.079-.081l-.017-.017a2.949 2.949 0 00-.082-.076l-.018-.017A3.05 3.05 0 0043.951 6l-3.383-2.668-3.38-2.673c-.051-.037-.095-.072-.144-.106l-.03-.021a2.951 2.951 0 00-.322-.189l-.01-.005c-.068-.038-.139-.064-.211-.094l-.043-.017q-.1-.038-.2-.07l-.02-.007a3.282 3.282 0 00-.488-.111h-.012A3.4 3.4 0 0035.22 0H17.178a3.107 3.107 0 00-3.314 2.836v18.807h-.171C6.143 21.643 0 26.901 0 33.363s6.143 11.72 13.693 11.72c6.106 0 11.291-3.439 13.052-8.173h14.822a3.107 3.107 0 003.314-2.836V7.828a2.083 2.083 0 00-.026-.31c0-.013 0-.026-.006-.039-.007-.042-.012-.079-.024-.124zm-2.852-.515h-4.9a.476.476 0 01-.508-.434v-3.84zM25.137 35.148a8.786 8.786 0 01-.576 1.761c-1.674 3.745-5.916 6.412-10.872 6.412-6.416 0-11.636-4.468-11.636-9.959S7.277 23.4 13.693 23.4h.171a13.527 13.527 0 012.057.182c5.353.893 9.407 4.936 9.407 9.775a8.585 8.585 0 01-.191 1.791zm17.682-1.075a1.178 1.178 0 01-1.256 1.075H27.228a10.105 10.105 0 00.159-1.786c0-5.813-4.97-10.65-11.464-11.564V2.836a1.178 1.178 0 011.256-1.075H34.51v4.645a2.4 2.4 0 002.565 2.2h5.748v25.466z' />
      <path d='M15.922 25.232a11.414 11.414 0 00-2.057-.234h-.264c-5.441 0-9.868 3.789-9.868 8.446a7.543 7.543 0 001.4 4.345 1.131 1.131 0 001.412.3.813.813 0 00.352-1.208 5.967 5.967 0 01-1.11-3.438c0-3.686 3.5-6.685 7.811-6.685.088 0 .176 0 .264.006a8.977 8.977 0 012.057.3c3.177.848 5.49 3.389 5.49 6.384a5.77 5.77 0 01-.259 1.707 6.183 6.183 0 01-.876 1.761 8.161 8.161 0 01-6.675 3.217 9.219 9.219 0 01-1.075-.063 1.013 1.013 0 00-1.159.752.915.915 0 00.879.992 11.627 11.627 0 001.356.079 10.076 10.076 0 009-4.978 7.557 7.557 0 00.668-1.761 7.279 7.279 0 00.2-1.707c.001-3.98-3.221-7.32-7.546-8.215zM21.702 10.985a.891.891 0 100 1.761h15.353a.891.891 0 100-1.761zM33.967 17.469a.963.963 0 00-1.029-.881H21.702a.891.891 0 100 1.761h11.237a.963.963 0 001.028-.88zM37.051 22.192h-9.916a.891.891 0 100 1.761h9.916a.891.891 0 100-1.761zM37.053 27.795h-7.166a.891.891 0 100 1.761h7.166a.891.891 0 100-1.761z' />
      <path d='M13.601 34.321h3.456a.891.891 0 100-1.761h-2.863l-.329-.485-2.065-3.048a1.129 1.129 0 00-1.406-.32.815.815 0 00-.374 1.2l2.691 3.972a1.071 1.071 0 00.89.442zM9.599 39.373a.843.843 0 00-.095-.152.887.887 0 00-.128-.134.971.971 0 00-.156-.109 1.148 1.148 0 00-.37-.131 1.187 1.187 0 00-.4 0 1.136 1.136 0 00-.192.05 1.115 1.115 0 00-.177.081.972.972 0 00-.156.109.889.889 0 00-.129.134.835.835 0 00-.095.152.8.8 0 00-.059.165.747.747 0 00-.02.172.765.765 0 00.02.173.834.834 0 00.059.165.858.858 0 00.095.151.889.889 0 00.129.134.975.975 0 00.156.109 1.115 1.115 0 00.177.081 1.136 1.136 0 00.192.05 1.2 1.2 0 00.2.018 1.174 1.174 0 00.2-.018 1.148 1.148 0 00.37-.131.975.975 0 00.156-.109.887.887 0 00.128-.134.867.867 0 00.095-.151.84.84 0 00.059-.165.761.761 0 00.02-.173.748.748 0 00-.02-.172.8.8 0 00-.059-.165zM38.003 17.086a.836.836 0 00-.095-.152.881.881 0 00-.127-.134.974.974 0 00-.156-.109 1.127 1.127 0 00-.178-.081 1.175 1.175 0 00-.192-.05 1.187 1.187 0 00-.4 0 1.147 1.147 0 00-.37.131 1.01 1.01 0 00-.155.109.888.888 0 00-.129.134.841.841 0 00-.095.152.793.793 0 00-.059.165.743.743 0 00-.02.172.759.759 0 00.02.173.83.83 0 00.059.165.866.866 0 00.095.151.89.89 0 00.129.134 1.08 1.08 0 00.155.11 1.146 1.146 0 00.37.131 1.2 1.2 0 00.2.017 1.165 1.165 0 00.393-.067 1.125 1.125 0 00.178-.081 1.037 1.037 0 00.156-.11.882.882 0 00.127-.134.861.861 0 00.095-.151.837.837 0 00.059-.165.761.761 0 00.021-.173.748.748 0 00-.021-.172.794.794 0 00-.06-.165z' />
    </svg>
  )
}

export const AppRejRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 36.881 40.234' {...props}>
      <defs>
        <style>{'.prefix__a{fill:red}.prefix__b{fill:#fff}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M4.024 3.353A2.682 2.682 0 016.706.671h26.818a2.682 2.682 0 012.682 2.682v26.818l-8.042 8.051H6.706a2.682 2.682 0 01-2.682-2.682z'
      />
      <path
        className='prefix__b'
        d='M33.528.671h-2.012a2.682 2.682 0 012.682 2.682v26.818l-8.046 8.051h2.012l8.046-8.051V3.353A2.682 2.682 0 0033.528.671zM17.636 20.788l.846-1.175a2.012 2.012 0 013.266 2.351L9.678 38.727a2.012 2.012 0 01-1.471.829 1.271 1.271 0 01-.16.007 2.012 2.012 0 01-1.422-.587L1.26 33.609a2.012 2.012 0 112.845-2.845l3.692 3.692 7.91-10.986'
      />
      <path
        className='prefix__a'
        d='M2.093 30.766l3.692 3.691.842-1.169-2.522-2.522a2.006 2.006 0 00-2.428-.312 2 2 0 01.416.311zM21.292 19.155a2.012 2.012 0 00-2.188-.1c.058.034.12.062.176.1a2.012 2.012 0 01.457 2.808L7.668 38.727a2.006 2.006 0 01-.622.56 2 2 0 001 .276 1.319 1.319 0 00.162-.007 2.012 2.012 0 001.471-.829l12.07-16.764a2.012 2.012 0 00-.457-2.808z'
      />
      <path className='prefix__b' d='M28.164 38.223v-5.365a2.682 2.682 0 012.682-2.682h5.364' />
      <path d='M36.881 3.353A3.357 3.357 0 0033.528 0H6.706a3.357 3.357 0 00-3.353 3.353v23.47a.671.671 0 001.341 0V3.353a2.012 2.012 0 012.012-2.012h26.822a2.012 2.012 0 012.012 2.012V29.5h-4.694a3.357 3.357 0 00-3.353 3.353v4.694h-12.74a.671.671 0 100 1.341h13.411a.671.671 0 00.474-.2l8.047-8.047a.671.671 0 00.2-.474zm-6.035 27.493h3.746L28.834 36.6v-3.743a2.012 2.012 0 012.012-2.011z' />
      <path d='M0 32.187a2.663 2.663 0 00.786 1.9l5.364 5.361a2.667 2.667 0 001.9.786 1.8 1.8 0 00.216-.009 2.678 2.678 0 001.957-1.108l12.07-16.764a2.682 2.682 0 10-4.353-3.136l-.846 1.175a.671.671 0 101.088.783L19.028 20a1.341 1.341 0 112.177 1.566L9.135 38.335a1.335 1.335 0 01-.979.553A1.352 1.352 0 017.1 38.5l-5.366-5.365a1.341 1.341 0 010-1.9 1.373 1.373 0 011.9 0l3.692 3.691a.662.662 0 00.528.194.671.671 0 00.49-.276l7.913-10.987a.671.671 0 10-1.088-.783L7.711 33.425l-3.132-3.134a2.749 2.749 0 00-3.793 0A2.663 2.663 0 000 32.187zM9.388 4.023H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM32.187 4.023H12.071a.671.671 0 100 1.341h20.116a.671.671 0 100-1.341zM9.388 8.047H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM12.071 9.388h15.422a.671.671 0 100-1.341H12.071a.671.671 0 100 1.341zM9.388 12.07H8.047a.671.671 0 000 1.341h1.341a.671.671 0 100-1.341zM12.071 13.411H22.8a.671.671 0 100-1.341H12.071a.671.671 0 100 1.341zM34.199 14.082a.671.671 0 10-1.341 0v9.388a.671.671 0 101.341 0zM33.529 25.481a.671.671 0 00-.671.671v1.341a.671.671 0 001.341 0v-1.341a.671.671 0 00-.67-.671z' />
    </svg>
  )
}

export const ProgRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 44.06 45.04' {...props}>
      <defs>
        <style>{'.prefix__a{fill:red}.prefix__b{fill:#fff}.prefix__c{fill:#231f20}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M40.144 29.374l.147.049-4.847 14.541c-2.468-.382-2.889-1.861-6.08-1.861-3.672 0-3.672 1.958-7.343 1.958s-3.672-1.958-7.343-1.958c-3.182 0-3.6 1.478-6.061 1.861L3.771 29.423l.147-.049 18.113-8.327z'
      />
      <path
        className='prefix__b'
        d='M41.613 25.457l-1.321 3.965-.147-.049-18.115-8.322v-4.406l13.6 6.12z'
      />
      <path
        className='prefix__a'
        d='M12.728 10.77h21.051a1.964 1.964 0 011.958 1.958v9.791l-.108.245-13.6-6.12-13.6 6.12-.108-.245v-9.791a1.964 1.964 0 011.958-1.958z'
      />
      <path
        className='prefix__b'
        d='M22.031 16.645v4.406L3.917 29.374l-.147.049-1.322-3.965 5.982-2.693z'
      />
      <path className='prefix__a' d='M31.331 10.771h-18.6V4.896h18.6z' />
      <path
        className='prefix__c'
        d='M11.26 13.218h21.541v1.958H11.26zM30.842 16.156H32.8v1.958h-1.958zM11.26 16.156h1.958v1.958H11.26zM21.051 6.854h1.958v1.958h-1.958zM23.988 6.854h1.958v1.958h-1.958zM18.114 6.854h1.958v1.958h-1.958zM15.176 6.854h1.958v1.958h-1.958zM26.926 6.854h1.958v1.958h-1.958z'
      />
      <path
        className='prefix__c'
        d='M39.933 42.218a5.821 5.821 0 01-3.157.863l5.771-17.314a.979.979 0 00-.527-1.2l-5.3-2.384v-9.454a2.941 2.941 0 00-2.937-2.937h-1.472V4.9a.979.979 0 00-.979-.979h-8.323V0h-1.958v3.917h-8.318a.979.979 0 00-.979.979v4.9h-1.473a2.941 2.941 0 00-2.937 2.937v9.447l-5.3 2.384a.979.979 0 00-.527 1.2l5.771 17.314a5.821 5.821 0 01-3.155-.86 7.726 7.726 0 00-4.132-1.094v1.958a5.826 5.826 0 013.211.864 7.726 7.726 0 004.131 1.094 8.119 8.119 0 001.436-.111 7.937 7.937 0 002.7-.986 5.817 5.817 0 013.2-.862 5.826 5.826 0 013.211.864 7.727 7.727 0 004.132 1.094 7.727 7.727 0 004.132-1.094 6.408 6.408 0 016.423 0 7.977 7.977 0 002.705.985 8.106 8.106 0 001.435.111 7.727 7.727 0 004.132-1.094 5.827 5.827 0 013.211-.864v-1.96a7.726 7.726 0 00-4.127 1.095zm-.226-14.124l-16.693-7.67v-2.265l17.395 7.828zM13.708 5.875h16.645v3.916H13.708zm-3.427 5.875h23.5a.98.98 0 01.979.979V21.3l-12.327-5.548a.978.978 0 00-.8 0L9.301 21.3v-8.57a.98.98 0 01.98-.981zm10.77 6.41v2.264L4.358 28.093l-.7-2.106zm8.313 22.964a7.727 7.727 0 00-4.131 1.094 5.827 5.827 0 01-3.211.864 5.826 5.826 0 01-3.211-.864 7.726 7.726 0 00-4.132-1.094 7.715 7.715 0 00-4.123 1.092 7.92 7.92 0 01-1.292.591L4.981 29.962l16.07-7.383v11.69h1.958v-11.69l16.07 7.384-4.281 12.844a7.944 7.944 0 01-1.3-.593 7.747 7.747 0 00-4.134-1.091z'
      />
    </svg>
  )
}

export const CompRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 40.999 40.998' {...props}>
      <defs>
        <style>{'.prefix__b{fill:red}.prefix__c{fill:#fff}'}</style>
      </defs>
      <path d='M26.9 12.544l-11.521-.428-9.293 3.098 14.414 4.8 14.414-4.8z' fill='#e2e2e2' />
      <path
        className='prefix__b'
        d='M28.507 8.008A7.207 7.207 0 1121.3.799a7.207 7.207 0 017.207 7.209zM34.907 22.162l-14.408-2.145-12.811 2.484v12.893l12.812 4.8 14.414-4.8V22.181z'
      />
      <path className='prefix__c' d='M28.292 6.255L26.9 12.543l8.012 2.671 5.285-5.285z' />
      <path
        className='prefix__c'
        d='M20.5 20.019l4.265 5.331 15.434-4.85-5.286-5.288zM2.709 20.841l14.414 4.8 3.376-5.627-14.414-4.802z'
      />
      <path className='prefix__c' d='M14.482 5.663L.801 9.929l5.285 5.285 9.293-3.1z' />
      <path d='M12.492 31.226a.8.8 0 10-.8-.8.8.8 0 00.8.8z' />
      <path d='M5.077 15.338l-3.054 5.091a.8.8 0 00.433 1.169l4.43 1.477v12.323a.8.8 0 00.52.75c2.709 1.016 12.773 4.79 12.827 4.809a.8.8 0 00.512.007h.007l14.414-4.8a.8.8 0 00.548-.76V22.748l4.724-1.485a.8.8 0 00.326-1.33l-4.719-4.719 4.719-4.716a.8.8 0 00-.33-1.331L28.943 5.618a8.009 8.009 0 00-15.071-.6L.562 9.165a.8.8 0 00-.328 1.333zm1.364.839L19.3 20.463l-2.532 4.22-12.859-4.285zm8.646-3.119a8.005 8.005 0 0012.065.414l5.228 1.742-11.88 3.961-11.882-3.961zm-6.6 10.553c8.82 2.925 8.39 2.835 8.635 2.835a.8.8 0 00.687-.389L19.7 22.91v16.132L8.487 34.838V23.611zm25.624 11.2L21.3 39.087V22.298l2.839 3.548a.8.8 0 00.865.264l9.108-2.862zm4.589-14.68l-13.651 4.29-3.2-4.006L34.7 16.13zm-4-5.833l-6.528-2.176a8.03 8.03 0 001.135-4.114c0-.2-.009-.406-.024-.609l9.408 2.9zm-13.4-12.7a6.4 6.4 0 016.406 6.41 6.405 6.405 0 01-6.406 6.406 6.408 6.408 0 110-12.816zm-7.923 5.248a8.074 8.074 0 00-.085 1.161 7.959 7.959 0 00.888 3.665L6.3 14.298l-4-4z' />
      <path d='M15.233 32.237l1.781.668a.8.8 0 10.562-1.5l-1.781-.668a.8.8 0 10-.562 1.5zM17.577 34.826l-4.8-1.8a.8.8 0 10-.562 1.5l4.8 1.8a.8.8 0 00.562-1.5zM19.933 10.976a.8.8 0 001.132 0l4.8-4.8a.801.801 0 00-1.133-1.133l-4.233 4.235-1.035-1.035a.801.801 0 00-1.133 1.133z' />
    </svg>
  )
}

export const FinalRed = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 45.01 41.81' {...props}>
      <defs>
        <style>{'.prefix__a,.prefix__b{fill:red}.prefix__a{stroke:#000}'}</style>
      </defs>
      <g transform='translate(-22.5 -40.14)'>
        <path d='M52.224 62.232h14v18h-14z' />
        <path
          className='prefix__a'
          d='M40.063 59.653l16.4-9.468-7.713-4.453-16.4 9.468zM39.433 60.798L23 51.329v18.939l16.433 9.469z'
        />
        <path className='prefix__b' d='M53.978 70.494h1.794v1.794h-1.794z' />
        <path
          className='prefix__a'
          d='M40.755 60.798v18.939l8.972-5.188V60.877a2.837 2.837 0 012.823-2.851h2.353a2.317 2.317 0 012.212-1.7h-.02v-5z'
        />
        <rect
          className='prefix__a'
          width={5.761}
          height={1.983}
          rx={0.992}
          transform='translate(56.15 57.649)'
        />
        <path className='prefix__a' d='M47.426 44.969l-7.364-4.252-16.4 9.468 7.364 4.252z' />
        <path
          d='M65.491 59.349h-2.378a2.261 2.261 0 01-2.186 1.606h-3.813a2.261 2.261 0 01-2.186-1.606H52.55a1.513 1.513 0 00-1.5 1.528v19.087a1.476 1.476 0 001.5 1.484h12.941a1.491 1.491 0 001.519-1.484V60.877a1.529 1.529 0 00-1.518-1.528zM52.65 63.791a.66.66 0 01.656-.664h3.114a.67.67 0 01.668.664v.445a.663.663 0 01-.665.66.637.637 0 01-.629-.446h-1.823v1.794h.026a.661.661 0 110 1.322h-.692a.656.656 0 01-.656-.66zm0 6.041a.661.661 0 01.661-.661h3.117a.661.661 0 01.661.661v3.117a.661.661 0 01-.661.661h-3.117a.661.661 0 01-.661-.661zm1.348 9.822h-.692a.638.638 0 01-.656-.638v-3.114a.679.679 0 01.656-.687h3.114a.69.69 0 01.668.687v.637a.665.665 0 11-1.329 0h-1.788v1.795h.026a.661.661 0 110 1.322zm4.879-2.575l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.941-1.928a.661.661 0 01.935.935zm0-12.207l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.936-1.936a.661.661 0 01.935.935zm5.848 13.27h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322z'
          fill='#95c123'
          stroke='#000'
        />
        <path
          className='prefix__b'
          d='M65.492 59.349h-2.378a2.261 2.261 0 01-2.186 1.606h-3.813a2.261 2.261 0 01-2.186-1.606h-2.378a1.513 1.513 0 00-1.5 1.528v19.087a1.476 1.476 0 001.5 1.484h12.941a1.491 1.491 0 001.519-1.484V60.877a1.529 1.529 0 00-1.518-1.528zm-12.841 4.442a.66.66 0 01.656-.664h3.114a.67.67 0 01.668.664v.445a.663.663 0 01-.665.66.637.637 0 01-.629-.446h-1.823v1.794h.026a.661.661 0 110 1.322h-.692a.656.656 0 01-.656-.66zm0 6.041a.661.661 0 01.661-.661h3.117a.661.661 0 01.661.661v3.117a.661.661 0 01-.661.661h-3.117a.661.661 0 01-.661-.661zm1.348 9.822h-.692a.638.638 0 01-.656-.638v-3.114a.679.679 0 01.656-.687h3.114a.69.69 0 01.668.687v.637a.665.665 0 11-1.329 0h-1.788v1.795h.026a.661.661 0 110 1.322zm4.879-2.575l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.941-1.928a.661.661 0 01.935.935zm0-12.207l-2.4 2.4a.661.661 0 01-.935 0l-1.057-1.056a.661.661 0 01.935-.935l.589.589 1.936-1.936a.661.661 0 01.935.935zm5.848 13.27h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322zm0-6.044h-4.152a.661.661 0 010-1.322h4.152a.661.661 0 010 1.322z'
        />
      </g>
    </svg>
  )
}
